import React from "react"
import { Form, Field } from "react-final-form"
import CardTitle from "components/shared/CardTitle"
import { FormInput, Checkbox } from "components/shared/form"
import { FlexTitle } from "components/shared/styled"

import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core"

const UserForm = ({ submit, user }) => (
  <Form
    onSubmit={submit}
    initialValues={user}
    render={({ handleSubmit, pristine, submitting, values }) => (
      <form onSubmit={handleSubmit}>
        <Card style={{ position: "relative", height: "100%" }}>
          <CardTitle>
            <FlexTitle>
              <Typography variant="h5">User Edit</Typography>
              <Button
                type="submit"
                variant="text"
                color="inherit"
                disabled={submitting}
              >
                Save
              </Button>
            </FlexTitle>
          </CardTitle>
          <CardContent>
            <Field
              name="displayName"
              component={FormInput}
              label="Display Name"
              type="text"
              fullWidth
            />
            <Field
              name="email"
              component={FormInput}
              label="Email"
              type="email"
              fullWidth
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Field name="isAdmin" component={Checkbox} type="checkbox" />
                }
                label="Administrator?"
              />
            </FormGroup>
          </CardContent>
        </Card>
      </form>
    )}
  />
)

export default UserForm
