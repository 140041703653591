import React, { useEffect } from "react"

import { connect } from "react-redux"
import MaterialTable, { MTableToolbar } from "material-table"
import { Switch, Route } from "react-router"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { Layout } from "components/shared/styled"

import {
  listPlannerTemplates,
  savePlannerTemplate
} from "../../../actions/templatePlannerActions"

import { listNounTemplates } from "../../../actions/templateNounActions"

import PlannerTemplateForm from "./PlannerTemplateForm"

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
`

export const PlannerTemplateList = props => {
  const { list, loading, history, match, nouns } = props
  const { listPlannerTemplates, listNounTemplates, savePlannerTemplate } = props

  useEffect(() => {
    listPlannerTemplates()
    listNounTemplates()
  }, [listPlannerTemplates, listNounTemplates])

  return (
    <Layout>
      <MaterialTable
        title="Planner Templates"
        data={list}
        columns={[
          {
            title: "Name",
            field: "name"
          },
          {
            title: "Description",
            field: "desc"
          }
        ]}
        onRowClick={(event, rowData) =>
          history.push(`${match.path}/${rowData._id}`)
        }
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <ButtonBar>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`${match.path}/add`)}
                >
                  Add Planner Template
                </Button>
              </ButtonBar>
            </div>
          )
        }}
        options={{ sorting: true, pageSize: 10 }}
      />
      <Switch>
        <Route
          path={`${match.path}/add`}
          component={() => {
            if (loading) return <div>Loading...</div>
            return (
              <PlannerTemplateForm submit={savePlannerTemplate} nouns={nouns} />
            )
          }}
        />
        <Route
          path={`${match.path}/:id`}
          component={({ match }) => {
            const current = list.find(x => x._id == match.params.id)
            if (loading || !current) return <div>Loading...</div>
            return (
              <PlannerTemplateForm
                planner={current}
                submit={savePlannerTemplate}
                nouns={nouns}
              />
            )
          }}
        />
      </Switch>
    </Layout>
  )
}

export default connect(
  s => ({
    list: s.templatePlanners.list,
    nouns: s.templateNouns.list,
    loading: s.templatePlanners.isLoading
  }),
  { listPlannerTemplates, listNounTemplates, savePlannerTemplate }
)(PlannerTemplateList)
