import {
  USER_AUTH,
  USER_LIST,
  USER_SAVE,
  USER_LOGOUT,
  AUTH_URL,
  API_BASE,
  JWT_TOKEN
} from "lib/constants"
import { RSAA, getJSON } from "redux-api-middleware"
import { push } from "connected-react-router"

const path = "/admin/users"
export const getCurrentUser = profile => ({
  [RSAA]: {
    endpoint: `${API_BASE}/users/current`,
    method: "POST",
    body: JSON.stringify(profile),
    headers: {
      "content-type": "application/json"
    },
    types: [
      USER_AUTH.REQUEST,
      {
        type: USER_AUTH.SUCCESS,
        payload: (action, state, res) => {
          return getJSON(res)
        }
      },
      {
        type: USER_AUTH.FAILURE,
        meta: (action, state, res) => {
          console.log(action, res)
          if (res) {
            return getJSON(res)
          } else {
            return {
              status: "Network request failed"
            }
          }
        }
      }
    ]
  }
})

export const logoutAction = () => ({
  type: USER_LOGOUT
})

export const loginResponse = response => ({
  [RSAA]: {
    endpoint: AUTH_URL,
    method: "POST",
    body: JSON.stringify({
      access_token: response.accessToken
    }),
    headers: {
      "content-type": "application/json"
    },
    types: [
      USER_AUTH.REQUEST,
      {
        type: USER_AUTH.SUCCESS,
        payload: (action, state, res) => {
          return res.json().then(({ token, user }) => {
            localStorage.setItem(JWT_TOKEN, token)
            return user
          })
        }
      },
      USER_AUTH.FAILURE
    ]
  },
  authorize: false
})

export const getUserList = () => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}`,
    method: "GET",
    types: [USER_LIST.REQUEST, USER_LIST.SUCCESS, USER_LIST.FAILURE]
  }
})

export const saveUser = user => {
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${API_BASE}${path}/${user._id}`,
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
          "content-type": "application/json"
        },
        types: [USER_SAVE.REQUEST, USER_SAVE.SUCCESS, USER_SAVE.FAILURE]
      }
    })

    if (actionResponse.error) return actionResponse

    return dispatch(push(`${path}`))
  }
}
