import React from "react"
import styled from "styled-components"
import { palette } from "styled-theme"
import ContentEditable from "react-contenteditable"
import { InputLabel, TextField, FormControl } from "@material-ui/core"
import RTE from "./RTE"
import Editable from "./Editable/Editable"
const StyledInput = styled.input`
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background: ${palette("grayscale", 5)};
  font-size: 16px;

  &:hover {
    background: ${palette("greyscale", 0)};
    cursor: text;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    cursor: text;
  }
`
StyledInput.displayName = "StyledInput"

const StyledEditable = styled(ContentEditable)`
  background: ${palette("grayscale", 5)};
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  &:hover {
    background: ${palette("greyscale", 0)};
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    cursor: from text;
  }
`
StyledEditable.displayName = "StyledEditable"

const SRTE = styled(RTE)`
  padding-top: 1rem;

  .rdw-editor-main {
    overflow: visible;
  }
`

const FormInput = ({ input, meta, ...rest }) => {
  const { name, onChange, value, ...restInput } = input
  const showRTE =
    input.type == "rte" || (input.type == "textarea" && value.length > 60)

  const myOnChange = (...args) => {
    onChange(...args)
  }
  return showRTE ? (
    <div>
      <InputLabel shrink htmlFor={name}>
        {rest.label}
      </InputLabel>
      <Editable
        id={name}
        input={input}
        onChange={myOnChange}
        meta={meta}
        {...rest}
      />
    </div>
  ) : (
    <TextField
      {...rest}
      name={name}
      onChange={myOnChange}
      value={value}
      inputProps={restInput}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      fullWidth
      multiline={rest.type == "textarea"}
    />
  )
}

export default FormInput
