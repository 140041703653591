import React from "react"
import { Route } from "react-router-dom"
import { Typography } from "@material-ui/core"
import Card from "./shared/Card"
import { Layout } from "./shared/styled"

import PlannerList from "./planners/PlannerList"
import PlannerAdd from "./planners/PlannerAdd"
import News from "./News"

const Home = ({ isAuth }) => (
  <React.Fragment>
    <Route path="/new" component={PlannerAdd} />
    <Layout noHeader>
      {isAuth && <PlannerList />}
      <Card
        title={
          <Typography variant="h5" color="inherit">
            Box Turtle News
          </Typography>
        }
      >
        <Typography variant="body1" component="div">
          <News />
        </Typography>
      </Card>
    </Layout>
  </React.Fragment>
)

export default Home
