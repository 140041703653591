import React from "react"
import { Form, Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"
import { Button, Card, CardContent, Typography } from "@material-ui/core"
import { FormInput, ArrayEditor } from "components/shared/form"
import CardTitle from "components/shared/CardTitle"
import { FlexTitle } from "components/shared/styled"

const SectionTemplateForm = ({ submit, section }) => (
  <Form
    onSubmit={submit}
    initialValues={section}
    mutators={{
      ...arrayMutators
    }}
    render={({ handleSubmit, pristine, submitting, values }) => (
      <form onSubmit={handleSubmit}>
        <Card style={{ position: "relative", height: "100%" }}>
          <CardTitle>
            <FlexTitle>
              <Typography variant="h5">Section Edit</Typography>
              <Button
                type="submit"
                variant="text"
                color="inherit"
                disabled={submitting}
              >
                Save
              </Button>
            </FlexTitle>
          </CardTitle>
          <CardContent>
            <Field name="name" label="Name" component={FormInput} type="text" />
            <Field name="key" label="Key" component={FormInput} type="text" />
            <Field
              name="desc"
              label="Description"
              component={FormInput}
              type="textarea"
            />
            <FieldArray
              name="fields"
              component={ArrayEditor}
              showHeader
              title="Fields"
            />
          </CardContent>
        </Card>
      </form>
    )}
  />
)
export default SectionTemplateForm
