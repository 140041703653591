import React, { useState } from "react"
import { Text } from "slate"
import MentionNode from "./MentionNode"
import { Portal, Paper, List, ListItem, ListItemText } from "@material-ui/core"

const key = "mention"
const REGEX = /@(\S*)$/
const ANNOTATION_KEY = `annotatin_${key}`

const escapeRegExp = s => s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string

const getInput = value => {
  const { startText } = value

  // In some cases, like if the node that was selected gets deleted,
  // `startText` can be null.
  if (!startText) {
    return null
  }

  const startOffset = value.selection.start.offset
  const textBefore = startText.text.slice(0, startOffset)
  const result = REGEX.exec(textBefore)

  return result == null ? null : result[1]
}

const insertMention = (editor, item) => {
  const inputValue = getInput(editor.value)
  editor = editor.deleteBackward(inputValue.length + 1) //deletes the text + the @ sign

  const selectedRange = editor.value.selection

  editor
    .insertText(" ")
    .insertInlineAtRange(selectedRange, {
      data: {
        id: item.id,
        value: item.value
      },
      nodes: [Text.create(`@${item.value}`)],
      type: key
    })
    .focus()
}

const AnnotationNode = props => {
  const anchorRef = React.useRef()
  const onClose = e => {
    console.log("popover closed")
  }
  const showMentions = !!anchorRef.current
  let pos = { x: 0, y: 0 }
  if (showMentions) {
    const {
      top,
      left,
      width,
      height
    } = anchorRef.current.getBoundingClientRect()
    pos.x = Math.ceil(left + width)
    pos.y = Math.ceil(top)
  }
  return (
    <>
      <span
        ref={anchorRef}
        {...props.attributes}
        style={{ display: "inline-block" }}
      >
        {props.children}
      </span>
      {showMentions && (
        <Portal>
          <Paper
            style={{
              position: "absolute",
              top: `${pos.y}px`,
              left: `${pos.x}px`,
              marginLeft: "1rem",
              width: "200px"
            }}
          >
            <List dense={true}>
              {props.mentions.slice(0, 5).map(x => (
                <ListItem button key={x.id}>
                  <ListItemText
                    primary={x.value}
                    onClick={() => props.doSelection(x)}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Portal>
      )}
    </>
  )
}

const mention = () => {
  let mentions = []
  const renderInline = (props, editor, next) => {
    const { node, attributes, children } = props

    if (node.type != key) return next()

    const id = node.data.get("id")
    return (
      <MentionNode id={id} attributes={attributes}>
        {children}
      </MentionNode>
    )
  }

  let lastInputValue = null

  const search = str => {
    const s = escapeRegExp(str)
    const regex = RegExp(`${s}`, "gi")
    return mentions.filter(x => !str || x.value.match(regex))
  }
  const onChange = (editor, next) => {
    const pluginActive = Array.isArray(mentions) && mentions.length
    //if (!pluginActive) return next()
    const inputValue = getInput(editor.value)

    if (inputValue != lastInputValue) {
      const { selection } = editor.value

      lastInputValue = inputValue

      let annotations = editor.value.annotations.filter(ann => ann.type != key)

      if (inputValue) {
        console.log(`adding annotation with inputValue ${inputValue}`)
        annotations = annotations.set(ANNOTATION_KEY, {
          anchor: {
            key: selection.start.key,
            offset: selection.start.offset - inputValue.length
          },
          focus: {
            key: selection.start.key,
            offset: selection.start.offset
          },
          type: key,
          key: ANNOTATION_KEY
        })
      }
      setTimeout(() =>
        editor.withoutSaving(() => {
          console.log("calling setAnnotations", annotations.get(ANNOTATION_KEY))
          editor.setAnnotations(annotations)
        })
      )
    }

    return next()
  }

  const renderAnnotation = (props, editor, next) => {
    console.log("renderAnnotation", props)
    const fm = search(lastInputValue || "")

    const doSelection = mention => {
      editor.command(insertMention, mention)
    }

    if (props.annotation.type == key) {
      return (
        <AnnotationNode {...props} doSelection={doSelection} mentions={fm} />
      )
    }
    return next()
  }
  const plugin = {
    renderInline,
    onChange,
    renderAnnotation,
    commands: {
      insertMention
    }
  }

  return {
    plugin,
    key,
    setMentions: m => {
      mentions = m
    }
  }
}

export default mention
