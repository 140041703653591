import React from "react"
import CardTitle from "components/shared/CardTitle"

import { Card, Typography, CardContent } from "@material-ui/core"

const AdminHome = () => (
  <Card>
    <CardTitle>
      <Typography variant="h5">Admin Home</Typography>
    </CardTitle>
    <CardContent>
      <Typography>
        Welcome to the Admin Home. Pick an option on the right. Maybe one day
        we'll have a dashboard here
      </Typography>
    </CardContent>
  </Card>
)

export default AdminHome
