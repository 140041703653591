import React from "react"
import { Form, Field } from "react-final-form"
import { FormInput, AutoSave } from "components/shared/form"
import { Button, Card, CardContent, CardHeader } from "@material-ui/core"
import styled from "styled-components"
import isUrl from "validator/lib/isURL"
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
`
const Center = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
`
const Overview = styled.div`
  display: grid;
  justify-self: center;
  margin: 0 auto;
  grid-column: -1/1;
  grid-template-columns: repeat(2, minmax(250px, 440px)) 200px;
  grid-gap: 1rem;
  padding: 1rem;
`

const Media = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
`
const Buttons = styled.div`
  justify-self: right;
`

const CoverImage = styled.div`
  width: 100%;
  min-height: 20vh;
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const ItemForm = ({ item, items = [], handleSubmit, updateTab }) => {
  const struct = item.noun.struct || []

  const mentions = items.map(i => ({ value: i.name, id: i._id }))

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={item}
      render={({ form, handleSubmit, submitting }) => {
        let imageUrl = "/media/noimage.png"
        if (
          form.getFieldState("imageUrl") &&
          form.getFieldState("imageUrl").value &&
          isUrl(form.getFieldState("imageUrl").value)
        ) {
          imageUrl = form.getFieldState("imageUrl").value
        }
        return (
          <form onSubmit={handleSubmit}>
            <AutoSave change={updateTab} />
            <Center>
              <Overview>
                <div>
                  <Field
                    name="name"
                    label="Name"
                    component={FormInput}
                    type="text"
                  />
                  <Field
                    name="description"
                    label="Description"
                    component={FormInput}
                    type="rte"
                    mentions={mentions}
                  />
                </div>
                <Media>
                  <CoverImage url={imageUrl} />
                  <Field
                    name="imageUrl"
                    label="Image URL"
                    component={FormInput}
                    type="text"
                  />
                </Media>
                <Buttons>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </Button>
                </Buttons>
              </Overview>
            </Center>
            <Container>
              {struct.map(section => (
                <Card key={section.key}>
                  <CardContent>
                    <CardHeader title={section.name} />
                    {section.fields.map(field => (
                      <Field
                        key={`data.${section.key}.${field.id}`}
                        name={`data.${section.key}.${field.id}`}
                        label={field.name}
                        component={FormInput}
                        type="rte"
                        mentions={mentions}
                      />
                    ))}
                  </CardContent>
                </Card>
              ))}
            </Container>
          </form>
        )
      }}
    />
  )
}

export default ItemForm
