import {
  API_BASE,
  NEWS_LOAD,
  NEWS_LIST,
  NEWS_SAVE,
  NEWS_DELETE,
  NEWS_RECENT
} from "lib/constants"
import { RSAA } from "redux-api-middleware"

const path = "/admin/news"
const nonadmin = "/news"

export const recentNews = () => ({
  [RSAA]: {
    endpoint: `${API_BASE}${nonadmin}`,
    method: "GET",
    types: [NEWS_RECENT.REQUEST, NEWS_RECENT.SUCCESS, NEWS_RECENT.FAILURE]
  }
})

export const listNews = () => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}`,
    method: "GET",
    types: [NEWS_LIST.REQUEST, NEWS_LIST.SUCCESS, NEWS_LIST.FAILURE]
  }
})

export const loadNews = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}`,
    method: "GET",
    types: [NEWS_LOAD.REQUEST, NEWS_LOAD.SUCCESS, NEWS_LOAD.FAILURE]
  }
})

export const saveNews = news => {
  let endpoint = `${API_BASE}${path}`
  let method = "POST"
  if (news._id) {
    endpoint = `${endpoint}/${news._id}`
    method = "PUT"
  }

  return {
    [RSAA]: {
      endpoint,
      method,
      body: JSON.stringify(news),
      headers: {
        "content-type": "application/json"
      },
      types: [NEWS_SAVE.REQUEST, NEWS_SAVE.SUCCESS, NEWS_SAVE.FAILURE]
    }
  }
}

export const removeNews = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}`,
    method: "DELETE",
    types: [NEWS_DELETE.REQUEST, NEWS_DELETE.SUCCESS, NEWS_DELETE.FAILURE]
  }
})
