import React from "react"
import { Form, Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"

import { FormInput, NounSelector } from "components/shared/form"
import { Button, Card, CardContent, Typography } from "@material-ui/core"

import CardTitle from "components/shared/CardTitle"
import { FlexTitle } from "components/shared/styled"

const NounTemplateForm = ({ noun, submit, sections }) => (
  <Form
    onSubmit={submit}
    initialValues={noun}
    mutators={{
      ...arrayMutators
    }}
    render={({ handleSubmit, pristine, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Card style={{ position: "relative", height: "100%" }}>
          <CardTitle>
            <FlexTitle>
              <Typography variant="h5">Noun Edit </Typography>
              <Button
                type="submit"
                variant="text"
                color="inherit"
                disabled={submitting}
              >
                Save
              </Button>
            </FlexTitle>
          </CardTitle>
          <CardContent>
            <Field name="name" label="Name" component={FormInput} type="text" />
            <Field
              name="desc"
              label="Description"
              component={FormInput}
              type="textarea"
            />
            <FieldArray
              name="sections"
              component={NounSelector}
              label="Sections"
              nouns={sections}
            />
          </CardContent>
        </Card>
      </form>
    )}
  />
)

export default NounTemplateForm
