import { USER_GETCURRENT,
         USER_AUTH,
         USER_LIST,
         USER_SAVE
} from 'lib/constants'

const initialState = {
    currentUser:null,
    isAuthenticated:false,
    user:null,
    users:[]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_GETCURRENT:
            return {
                ...state,
                currentUser:action.payload
            }
        case USER_AUTH.SUCCESS:
            return {
                ...state,
                currentUser:action.payload,
                isAuthenticated:true
            }
        case USER_LIST.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case USER_LIST.SUCCESS:
            return {
                ...state,
                isLoading:false,
                users: action.payload
            }
        case USER_LIST.FAILURE:
            return {
                ...state,
                isLoading:false,
                users:[],
                apiError: action.payload
            }
        case USER_SAVE.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case USER_SAVE.SUCCESS:

            const users = state.users.slice()
            const index = users.findIndex(x => x._id == action.payload._id)
            if(index >= 0) {
                users[index] = action.payload
            } else {
                users.push(action.payload)
            }

            return {
                ...state,
                isLoading:false,
                user: action.payload,
                users
            }
        case USER_SAVE.FAILURE:
            return {
                ...state,
                isLoading:false,
                apiError: action.payload
            }
        default:
            return state
    }
}