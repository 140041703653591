import React, { useEffect } from "react"

import { connect } from "react-redux"
import MaterialTable, { MTableToolbar } from "material-table"
import { Switch, Route } from "react-router"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { Layout } from "components/shared/styled"

import {
  listNounTemplates,
  saveNounTemplate
} from "actions/templateNounActions"
import { listSectionTemplates } from "actions/templateSectionActions"

import NounTemplateForm from "./NounTemplateForm"

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
`

export const TemplateNounList = props => {
  const { list, loading, sections, history, match } = props
  const { listNounTemplates, listSectionTemplates, saveNounTemplate } = props

  useEffect(() => {
    listNounTemplates()
    listSectionTemplates()
  }, [listNounTemplates, listSectionTemplates])

  return (
    <Layout>
      <MaterialTable
        title="Noun Templates"
        data={list}
        columns={[
          {
            title: "Name",
            field: "name"
          },
          {
            title: "Description",
            field: "desc"
          }
        ]}
        onRowClick={(event, rowData) =>
          history.push(`${match.path}/${rowData._id}`)
        }
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <ButtonBar>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`${match.path}/add`)}
                >
                  Add Noun Template
                </Button>
              </ButtonBar>
            </div>
          )
        }}
        options={{ sorting: true, pageSize: 10 }}
      />
      <Switch>
        <Route
          path={`${match.path}/add`}
          component={() => {
            if (loading) return <div>Loading...</div>
            return (
              <NounTemplateForm submit={saveNounTemplate} sections={sections} />
            )
          }}
        />
        <Route
          path={`${match.path}/:id`}
          component={({ match }) => {
            const current = list.find(x => x._id == match.params.id)
            if (loading || !current) return <div>Loading...</div>
            return (
              <NounTemplateForm
                noun={current}
                submit={saveNounTemplate}
                sections={sections}
              />
            )
          }}
        />
      </Switch>
    </Layout>
  )
}

export default connect(
  s => ({
    list: s.templateNouns.list,
    sections: s.templateSections.list,
    loading: s.templateNouns.isLoading
  }),
  { listNounTemplates, listSectionTemplates, saveNounTemplate }
)(TemplateNounList)
