import React from "react"

import { AppBar, Link, Toolbar } from "@material-ui/core"

import styled from "styled-components"
import { useAuth0 } from "../../Auth0Provider"

import { Link as RouterLink } from "react-router-dom"

const AB = styled(AppBar)`
  & > div {
    display: flex;
  }
`
const TitleLink = styled(Link)`
  flex-grow: 1;
`
const NavBarLink = styled(Link)`
  margin-left: 2rem;
`
const NavBar = ({ localuser }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()

  return (
    <AB position="static">
      <Toolbar>
        <TitleLink variant="h6" component={RouterLink} color="inherit" to="/">
          Box Turtle
        </TitleLink>
        <NavBarLink
          variant="h6"
          color="inherit"
          href="https://bt.otbp.org/blog"
        >
          Blog
        </NavBarLink>
        {isAuthenticated ? (
          <>
            {localuser && localuser.isAdmin && (
              <NavBarLink
                variant="h6"
                component={RouterLink}
                color="inherit"
                to="/admin"
              >
                Admin
              </NavBarLink>
            )}
            {user && (
              <NavBarLink
                component="button"
                variant="h6"
                color="inherit"
                onClick={() => console.log("userclicked")}
              >
                {user.name}
              </NavBarLink>
            )}

            <NavBarLink
              component="button"
              variant="h6"
              color="inherit"
              onClick={() => logout()}
            >
              Log Out
            </NavBarLink>
          </>
        ) : (
          <NavBarLink
            component="button"
            variant="h6"
            color="inherit"
            onClick={() => loginWithRedirect({})}
          >
            Log In
          </NavBarLink>
        )}
      </Toolbar>
    </AB>
  )
}
export default NavBar
