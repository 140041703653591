import React from "react"
import { AppBar, Toolbar } from "@material-ui/core"

const CardTitle = ({ children }) => (
  <AppBar position="relative" color="secondary">
    <Toolbar>{children}</Toolbar>
  </AppBar>
)

export default CardTitle
