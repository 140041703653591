import { ST_LIST,
         ST_SAVE,
         ST_LOAD
} from 'lib/constants'

const initialState = {
    isAuthenticated:false,
    current:null,
    list:[]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ST_LIST.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case ST_LIST.SUCCESS:
            return {
                ...state,
                isLoading:false,
                list: action.payload
            }
        case ST_LIST.FAILURE:
            return {
                ...state,
                isLoading:false,
                list:[],
                apiError: action.payload
            }
        case ST_SAVE.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case ST_SAVE.SUCCESS:

            const list = state.list.slice()
            //yes this is a hill I will die on
            // eslint-disable-next-line
            const index = list.findIndex(x => x._id == action.payload._id)
            if(index >= 0) {
                list[index] = action.payload
            } else {
                list.push(action.payload)
            }



            return {
                ...state,
                isLoading:false,
                current: action.payload,
                list
            }
        case ST_SAVE.FAILURE:
            return {
                ...state,
                isLoading:false,
                apiError: action.payload
            }
        case ST_LOAD.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case ST_LOAD.SUCCESS:
            return {
                ...state,
                isLoading:false,
                current: action.payload
            }
        case ST_LOAD.FAILURE:
            return {
                ...state,
                isLoading:false,
                current:null,
                apiError: action.payload
            }
        default:
            return state
    }
}