import React, { useState } from "react"
import { IconButton, Paper } from "@material-ui/core"
import FileIcon from "@material-ui/icons/FileCopyOutlined"
import SearchIcon from "@material-ui/icons/Search"
import SettingsIcon from "@material-ui/icons/Settings"
import HomeIcon from "@material-ui/icons/Home"
import styled from "styled-components"
import { useTheme } from "@material-ui/styles"

import FileDrawer from "./FileDrawer"
import SearchDrawer from "./SearchDrawer"

const IconBar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`
const SideDrawer = styled(Paper)`
  height: 100%;
  display: flex;
`

const PlannerDrawer = ({ history, id, getUrl }) => {
  const theme = useTheme()
  const [drawer, setDrawer] = useState("file")

  const toggleDrawer = to => {
    if (to == drawer) setDrawer("")
    else setDrawer(to)
  }

  return (
    <SideDrawer elevation={3}>
      <IconBar theme={theme}>
        <IconButton color="inherit" onClick={() => history.push("/")}>
          <HomeIcon />
        </IconButton>
        <IconButton color="inherit" onClick={() => toggleDrawer("file")}>
          <FileIcon />
        </IconButton>
        <IconButton color="inherit" onClick={() => toggleDrawer("search")}>
          <SearchIcon />
        </IconButton>
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
        <IconButton
          color="inherit"
          onClick={() => history.push(`/planner/config/${id}`)}
        >
          <SettingsIcon />
        </IconButton>
      </IconBar>
      {drawer == "file" && <FileDrawer plannerId={id} getUrl={getUrl} />}
      {drawer == "search" && <SearchDrawer />}
    </SideDrawer>
  )
}

export default PlannerDrawer
