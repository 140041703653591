import { isRSAA, RSAA } from 'redux-api-middleware'
import { JWT_TOKEN } from 'lib/constants';


const apiAuthorize = store => next => action => {
    const authorize = !(action.authorize === false)

    if(isRSAA(action) && authorize) {
        const token = localStorage.getItem(JWT_TOKEN)
        action[RSAA].headers = action[RSAA].headers || {}
        action[RSAA].headers['Authorization'] = `Bearer ${token}`
    }

    return next(action)
}

export default apiAuthorize