import {
  PLANNER_LIST,
  PLANNER_LOAD,
  PLANNER_SAVE,
  PLANNER_PT_ADD,
  PLANNER_NT_ADD
} from "lib/constants"

const initialState = {
  isLoading: false,
  current: null,
  list: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PLANNER_LIST.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case PLANNER_LIST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload
      }
    case PLANNER_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        apiError: action.payload
      }
    case PLANNER_SAVE.SUCCESS:
    case PLANNER_LOAD.SUCCESS:
    case PLANNER_PT_ADD.SUCCESS:
    case PLANNER_NT_ADD.SUCCESS:
      const list = state.list.slice()
      const index = list.findIndex(x => x._id == action.payload._id)
      if (index >= 0) {
        list[index] = action.payload
      } else {
        list.push(action.payload)
      }
      return {
        ...state,
        list,
        isLoading: false,
        current: action.payload,
        plannerLoadFailure: false
      }
    case PLANNER_SAVE.REQUEST:
    case PLANNER_LOAD.REQUEST:
    case PLANNER_PT_ADD.REQUEST:
    case PLANNER_NT_ADD.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case PLANNER_LOAD.FAILURE: {
      return {
        ...state,
        isLoading: false,
        current: null,
        apiError: action.payload,
        plannerLoadFailure: true
      }
    }
    case PLANNER_SAVE.FAILURE:
    case PLANNER_PT_ADD.FAILURE:
    case PLANNER_NT_ADD.FAILURE:
      return {
        ...state,
        isLoading: false,
        current: null,
        apiError: action.payload
      }
    default:
      return state
  }
}
