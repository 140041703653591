import React, { useEffect } from "react"
import { connect } from "react-redux"
import MaterialTable from "material-table"
import { Route } from "react-router"
import { Layout } from "components/shared/styled"
import { getUserList, saveUser } from "../../../actions/userActions"

import UserForm from "./UserForm"

export const UserList = ({
  users = [],
  loading,
  history,
  match,
  getUserList,
  saveUser
}) => {
  useEffect(() => {
    getUserList()
  }, [getUserList])

  const data = users.map(x => ({ ...x, strAdmin: x.isAdmin ? "Yes" : "No" }))

  return (
    <Layout>
      <MaterialTable
        title="User List"
        data={data}
        columns={[
          {
            title: "Name",
            field: "displayName"
          },
          {
            title: "Email",
            field: "email"
          },
          {
            title: "Admin",
            field: "strAdmin"
          }
        ]}
        onRowClick={(event, rowData) =>
          history.push(`/admin/users/${rowData._id}`)
        }
        options={{ sorting: true, pageSize: 10 }}
      />
      <Route
        path={`${match.path}/:id`}
        component={({ match }) => {
          const current = users.find(x => x._id == match.params.id)
          if (loading || !current) return <div>Loading...</div>
          return <UserForm submit={saveUser} user={current} />
        }}
      />
    </Layout>
  )
}

export default connect(
  s => ({
    users: s.user.users,
    loading: s.user.isLoading
  }),
  { getUserList, saveUser }
)(UserList)
