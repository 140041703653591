import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import configurestore, { history } from "./store"
import { ConnectedRouter } from "connected-react-router"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { Auth0Provider } from "./Auth0Provider"
import config from "./config.json"

import { StylesProvider, ThemeProvider } from "@material-ui/styles"

import theme from "./lib/theme"

const onRedirectCallback = appState => {
  console.log("onRedirectCallback", appState)
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    client_id={config.AUTH0_CLIENT_ID}
    audience={config.AUTH0_BT_AUDIENCE}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={configurestore()}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
