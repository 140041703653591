import React from "react"
import styled from "styled-components"
import BoldIcon from "@material-ui/icons/FormatBold"
import ItalicIcon from "@material-ui/icons/FormatItalic"
import UnderlineIcon from "@material-ui/icons/FormatUnderlined"
import StrikeIcon from "@material-ui/icons/StrikethroughS"
import UnorderedList from "@material-ui/icons/FormatListBulleted"
import OrderedList from "@material-ui/icons/FormatListNumbered"
import Outdent from "@material-ui/icons/FormatIndentDecrease"
import Indent from "@material-ui/icons/FormatIndentIncrease"
import FormatColorText from "@material-ui/icons/FormatColorText"
import FormatColorFill from "@material-ui/icons/FormatColorFill"
import LinkIcon from "@material-ui/icons/Link"

const StyledToolbar = styled.div`
  & .MuiInput-root {
    margin-left: 5px;
    top: -7px;
  }
  & .MuiSelect-select {
    padding-bottom: 3px;
    width: 3.1rem;
  }
  & .slate-button {
    vertical-align: baseline;
    color: black;
    opacity: 0.4;
    &.active {
      opacity: 1;
    }

    & .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
`

const Toolbar = ({ editor, val, buttons = [] }) => {
  const renderButton = (key, icon) => {
    const button = buttons.find(x => x.key == key)
    if (!button) return <></>

    return button.button(editor, val, icon)
  }

  return (
    <StyledToolbar>
      {renderButton("bold", <BoldIcon />)}
      {renderButton("italic", <ItalicIcon />)}
      {renderButton("underline", <UnderlineIcon />)}
      {renderButton("strikethrough", <StrikeIcon />)}
      {renderButton("formatList", <StrikeIcon />)}
      {renderButton("list", [
        <UnorderedList />,
        <OrderedList />,
        <Indent />,
        <Outdent />
      ])}
      {renderButton("color", [<FormatColorText />, <FormatColorFill />])}
      {renderButton("link", <LinkIcon />)}
    </StyledToolbar>
  )
}

export default Toolbar
