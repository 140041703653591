import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import apiAuthorize from './middleware/apiAuthorize'
import createRootReducer from './reducers'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from  'connected-react-router'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const history = createBrowserHistory()

export default function configureStore() {
    return createStore(
        createRootReducer(history),
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            apiAuthorize,
            apiMiddleware,
            thunk
        ))
    )
}