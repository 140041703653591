import {
  API_BASE,
  PLANNER_LOAD,
  PLANNER_LIST,
  PLANNER_SAVE,
  PLANNER_PT_ADD,
  PLANNER_NT_ADD,
  JWT_TOKEN
} from "lib/constants"
import { RSAA } from "redux-api-middleware"

const path = "/planners"

export const listPlanners = () => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/`,
    method: "GET",
    types: [PLANNER_LIST.REQUEST, PLANNER_LIST.SUCCESS, PLANNER_LIST.FAILURE]
  }
})

export const loadPlanner = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}`,
    method: "GET",
    types: [PLANNER_LOAD.REQUEST, PLANNER_LOAD.SUCCESS, PLANNER_LOAD.FAILURE]
  }
})

export const savePlanner = planner => {
  let endpoint = `${API_BASE}${path}`
  let method = "POST"
  if (planner._id) {
    endpoint = `${endpoint}/${planner._id}`
    method = "PUT"
  }

  return {
    [RSAA]: {
      endpoint,
      method,
      body: JSON.stringify(planner),
      headers: {
        "content-type": "application/json"
      },
      types: [PLANNER_SAVE.REQUEST, PLANNER_SAVE.SUCCESS, PLANNER_SAVE.FAILURE]
    }
  }
}

export const getNounsForPlannerTemplates = async templates => {
  const token = localStorage.getItem(JWT_TOKEN)

  const endpoint = `${API_BASE}${path}/addpt?ids=${templates.join(",")}`
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
  const json = await response.json()
  return json
}

export const getNounsForNounTemplates = async templates => {
  const token = localStorage.getItem(JWT_TOKEN)

  const endpoint = `${API_BASE}${path}/addnt?ids=${templates.join(",")}`
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
  const json = await response.json()
  return json
}

export const addPlannerTemplates = (id, templates) => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}/addpt`,
    method: "POST",
    body: JSON.stringify(templates),
    headers: {
      "content-type": "application/json"
    },
    types: [
      PLANNER_PT_ADD.REQUEST,
      PLANNER_PT_ADD.SUCCESS,
      PLANNER_PT_ADD.FAILURE
    ]
  }
})

export const addNounTemplates = (id, templates) => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}/addnt`,
    method: "POST",
    body: JSON.stringify(templates),
    headers: {
      "content-type": "application/json"
    },
    types: [
      PLANNER_NT_ADD.REQUEST,
      PLANNER_NT_ADD.SUCCESS,
      PLANNER_NT_ADD.FAILURE
    ]
  }
})
