import React from "react"
import { IconButton } from "@material-ui/core"

const simpleMark = mark => {
  const renderMark = (props, editor, next) => {
    return props.mark.type == mark.mark
      ? React.createElement(mark.tag, props.attributes, props.children)
      : next()
  }
  const plugin = {
    renderMark
  }

  const active = value => value.activeMarks.some(x => x.type == mark.mark)
  const style = editor => editor.toggleMark(mark.mark).focus()

  const button = (editor, value, icon) => {
    const isActive = active(value)
    return (
      <IconButton
        className={`slate-button ${isActive && "active"}`}
        aria-label={mark.mark}
        size="small"
        onClick={() => style(editor)}
      >
        {icon}
      </IconButton>
    )
  }

  return {
    plugin,
    button,
    key: mark.mark
  }
}

export default simpleMark
