import React from "react"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { Button, Card, CardContent, Typography } from "@material-ui/core"
import uniqid from "uniqid"

import CardTitle from "components/shared/CardTitle"
import { FlexTitle } from "components/shared/styled"
import { ArrayEditor, FormInput } from "components/shared/form"

const emptyField = () => ({
  id: uniqid(),
  name: ""
})

const SectionItem = ({ input, editSection, checkClose }) => {
  return (
    <Card
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 3fr 8fr 1fr",
        height: "calc(100vh - 64px - 2rem)"
      }}
    >
      <CardTitle>
        <FlexTitle>
          <Typography variant="h5" color="inherit">
            Section Edit
          </Typography>
          <Button
            type="button"
            variant="text"
            color="inherit"
            onClick={() => checkClose("section", input.name)}
          >
            Done
          </Button>
        </FlexTitle>
      </CardTitle>
      <CardContent>
        <Field name={`${input.name}.name`} label="Name" component={FormInput} />
        <Field
          name={`${input.name}.desc`}
          label="Descrption"
          component={FormInput}
          type="textarea"
        />
      </CardContent>
      <CardContent
        style={{
          maxHeight: "100%",
          overflow: "auto"
        }}
      >
        <FieldArray
          name={`${input.name}.fields`}
          component={ArrayEditor}
          showHeader
          accessor=".name"
          title="Fields"
          newField={() => emptyField()}
        />
      </CardContent>
    </Card>
  )
}
export default SectionItem
