import React from "react"
import styled from "styled-components"
import PlannerDrawer from "./PlannerDrawer"
import TabbedEditor from "./TabbedEditor"
//presentational component for planners
const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(30px, min-content) 1fr;
  height: 100vh;
  width: 100vw;
`
const Planner = ({
  planner = {},
  match: {
    params: { id, item }
  },
  savePlanner,
  history
}) => {
  const getUrl = item => {
    if (item) return `/planner/${id}/${item}`
    return `/planner/${id}`
  }

  return (
    <Container>
      <PlannerDrawer history={history} id={id} getUrl={getUrl} />
      <TabbedEditor history={history} item={item} getUrl={getUrl} />
    </Container>
  )
}

export default Planner
