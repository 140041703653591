import React from "react"

const simpleBlock = block => {
  const renderBlock = (props, editor, next) => {
    return props.node.type == block.block
      ? React.createElement(block.tag, props.attributes, props.children)
      : next()
  }
  const plugin = {
    renderBlock
  }

  return {
    plugin,
    key: block.block
  }
}

export default simpleBlock
