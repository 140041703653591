import { NT_LIST,
         NT_SAVE,
         NT_LOAD
} from 'lib/constants'

const initialState = {
    isAuthenticated:false,
    current:null,
    list:[]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case NT_LIST.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case NT_LIST.SUCCESS:
            return {
                ...state,
                isLoading:false,
                list: action.payload
            }
        case NT_LIST.FAILURE:
            return {
                ...state,
                isLoading:false,
                list:[],
                apiError: action.payload
            }
        case NT_SAVE.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case NT_SAVE.SUCCESS:
            const list = state.list.slice()

            const index = list.findIndex(x => x._id == action.payload._id)
            if(index >= 0) {
                list[index] = action.payload
            } else {
                list.push(action.payload)
            }



            return {
                ...state,
                isLoading:false,
                current: action.payload,
                list
            }
        case NT_SAVE.FAILURE:
            return {
                ...state,
                isLoading:false,
                apiError: action.payload
            }
        case NT_LOAD.REQUEST:
            return {
                ...state,
                isLoading:true
            }
        case NT_LOAD.SUCCESS:
            return {
                ...state,
                isLoading:false,
                current: action.payload
            }
        case NT_LOAD.FAILURE:
            return {
                ...state,
                isLoading:false,
                current:null,
                apiError: action.payload
            }
        default:
            return state
    }
}