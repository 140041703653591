import React from "react"

import { Field, Form } from "react-final-form"
import { FormInput } from "components/shared/form"
import styled from "styled-components"
import { FormGrid } from "components/shared/styled"
import { Button } from "@material-ui/core"
const HelpDiv = styled.div`
  grid-column: 1/-1;
  padding: 10px;
  line-height: 1.2rem;
  & p {
    margin-bottom: 10px;
  }
`

const PlannerForm = ({ submit }) => (
  <Form
    onSubmit={submit}
    render={({ handleSubmit, pristine, submitting, showHelp }) => (
      <form onSubmit={handleSubmit}>
        <FormGrid>
          <Field name="name" label="Name" component={FormInput} type="text" />
          <Field
            name="description"
            label="Description"
            component={FormInput}
            type="textarea"
          />
          {showHelp ? (
            <HelpDiv>
              <p>
                To add a planner, select a name and enter an optional
                description. The description box will grow as you type -- like
                most inputs in BoxTurtle.
              </p>

              <p>
                Once you are happy with the name, press submit and you'll be
                taken to a screen to select a planner template -- it will get
                you up and running quickly and adding in the different things
                you want to track with your planner.
              </p>
            </HelpDiv>
          ) : (
            ""
          )}
          <Button
            className="buttons"
            type="submit"
            disabled={pristine || submitting}
          >
            Submit
          </Button>
        </FormGrid>
      </form>
    )}
  />
)

export default PlannerForm
