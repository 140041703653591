import React, { useEffect } from "react"
import { connect } from "react-redux"

import { recentNews } from "../actions/newsActions"

export const News = props => {
  const { news, recentNews, loading } = props

  useEffect(() => {
    if (loading || news.length > 0) return
    recentNews()
  }, [news, recentNews, loading])

  if (loading) {
    return <div>News....</div>
  }

  return (
    <React.Fragment>
      {news.map(item => (
        <div
          key={item._id}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      ))}
    </React.Fragment>
  )
}

export default connect(
  s => ({
    news: s.news.list || [],
    loading: s.news.isLoading
  }),
  { recentNews }
)(News)
