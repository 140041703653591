import React from "react"
import { Checkbox } from "@material-ui/core"

const CheckBoxWrapper = ({
  input: { checked, name, onChange, ...restInput },
  meta,
  ...rest
}) => (
  <Checkbox
    {...rest}
    name={name}
    inputProps={restInput}
    onChange={onChange}
    checked={checked}
  />
)

export default CheckBoxWrapper
