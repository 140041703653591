const buildActions = base => {
  const o = {}
  o.FAILURE = `${base}_FAILURE`
  o.SUCCESS = `${base}_SUCCESS`
  o.REQUEST = `${base}_REQUEST`
  return o
}

export const USER_GETCURRENT = "USER_GETCURRENT"
export const USER_LOGINRESPONSE = "USER_LOGINRESPONSE"

//user auth api
export const USER_AUTH = buildActions("USER_AUTH")
USER_AUTH.WITH_TOKEN = "USER_AUTH_WITH_TOKEN"
export const JWT_TOKEN = "JWT_TOKEN"

export const USER_LOGOUT = "USER_LOGOUT"

//admin users
export const USER_LIST = buildActions("USER_LIST")
export const USER_SAVE = buildActions("USER_SAVE")

//admin section templates
export const ST_LIST = buildActions("ST_LIST")
export const ST_LOAD = buildActions("ST_LOAD")
export const ST_SAVE = buildActions("ST_SAVE")

//admin noun templates
export const NT_LIST = buildActions("NT_LIST")
export const NT_LOAD = buildActions("NT_LOAD")
export const NT_SAVE = buildActions("NT_SAVE")

//admin planner templates
export const PT_LIST = buildActions("PT_LIST")
export const PT_LOAD = buildActions("PT_LOAD")
export const PT_SAVE = buildActions("PT_SAVE")

//admin news items
export const NEWS_LIST = buildActions("NEWS_LIST")
export const NEWS_LOAD = buildActions("NEWS_LOAD")
export const NEWS_SAVE = buildActions("NEWS_SAVE")
export const NEWS_DELETE = buildActions("NEWS_DELETE")

//planners
export const PLANNER_LIST = buildActions("PLANNER_LIST")
export const PLANNER_LOAD = buildActions("PLANNER_LOAD")
export const PLANNER_SAVE = buildActions("PLANNER_SAVE")
export const PLANNER_PT_ADD = buildActions("PLANNER_PT_ADD")
export const PLANNER_NT_ADD = buildActions("PLANNER_NT_ADD")

//planner-editor
export const NOUNITEM_LIST = buildActions("NOUNITEM_LIST")
export const NOUNITEM_ADD = buildActions("NOUNITEM_ADD")
export const NOUNITEM_DELETE = buildActions("NOUNITEM_DELETE")
export const NOUNITEM_LOAD = buildActions("NOUNITEM_LOAD")
export const NOUNITEM_SAVE = buildActions("NOUNITEM_SAVE")
export const EDITOR_SETTAB = "EDITOR_SETTAB"
export const EDITOR_UPDATETAB = "EDITOR_UPDATETAB"
export const EDITOR_CLOSETAB = "EDITOR_CLOSETAB"

//news
export const NEWS_RECENT = buildActions("NEWS_RECENT")

//urls
export const API_BASE = "/api"
export const ADMIN_BASE = `${API_BASE}/admin`
export const AUTH_URL = `/auth/google`

//server
export const NOT_IMPLEMENTED = "NOT IMPLEMENTED"
export const NOT_FOUND = "NOT FOUND"
export const AJAX_FAILED = "AJAX_FAILED"
export const AJAX_SUCCESS = "AJAX_SUCCESS"
export const AJAX_LOADING = "AJAX_LOADING"

//editor constants

export const BLOCK_TAGS = [
  { tag: "p", block: "paragraph", label: "Normal" },
  { tag: "li", block: "list-item" },
  { tag: "ul", block: "unordered-list" },
  { tag: "ol", block: "ordered-list" },
  { tag: "blockquote", block: "quote", simple: true },
  { tag: "pre", block: "code", label: "Code", simple: true },
  { tag: "h1", block: "heading-one", label: "h1" },
  { tag: "h2", block: "heading-two", label: "h2" },
  { tag: "h3", block: "heading-three", label: "h3" },
  { tag: "h4", block: "heading-four", label: "h4" },
  { tag: "h5", block: "heading-five", label: "h5" },
  { tag: "h6", block: "heading-six", label: "h6" }
]

//marks are inline tags
export const MARK_TAGS = [
  { tag: "strong", mark: "bold", icon: "format_bold" },
  { tag: "em", mark: "italic", icon: "format_italic" },
  { tag: "u", mark: "underline", icon: "format_underline" },
  { tag: "s", mark: "strikethrough", icon: "strikethrough_s" },
  { tag: "sup", mark: "superscript", icon: "format_superscrpt" },
  { tag: "sub", mark: "subscript", icon: "format_subscript" },
  { tag: "code", mark: "code", icon: "code" }
]

export const DEFAULT_NODE = "paragraph"
