import React from "react"
import { Form, Field } from "react-final-form"
import { Button, Card, CardContent, Typography } from "@material-ui/core"
import { FormInput } from "components/shared/form"
import CardTitle from "components/shared/CardTitle"
import { FlexTitle } from "components/shared/styled"

const NewsForm = ({ submit, news }) => (
  <Form
    onSubmit={submit}
    initialValues={news}
    render={({ handleSubmit, pristine, submitting, values }) => (
      <form onSubmit={handleSubmit}>
        <Card style={{ position: "relative", height: "100%" }}>
          <CardTitle>
            <FlexTitle>
              <Typography variant="h5">News Edit</Typography>
              <Button
                type="submit"
                variant="text"
                color="inherit"
                disabled={submitting}
              >
                Save
              </Button>
            </FlexTitle>
          </CardTitle>
          <CardContent>
            <Field name="name" label="Name" component={FormInput} type="text" />
            <Field
              name="created"
              label="Created"
              type="datetime-local"
              component={FormInput}
            />
            <Field
              name="description"
              label="Description"
              component={FormInput}
              type="rte"
            />
          </CardContent>
        </Card>
      </form>
    )}
  />
)
export default NewsForm
