import React from "react"
import { Field } from "react-final-form"
import { FormInput } from "./index"
import styled from "styled-components"
import { IconButton, Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { fieldArrayOnDrag } from "../../../utils/form-uitls"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(10px, min-content);
`
const FieldItem = styled.div`
  display: grid;
  grid-template-columns: minmax(10px, min-content) 1fr minmax(10px, min-content);
`

const FieldList = styled.div`
  grid-column: -1/1;
`

const Handle = styled.div`
  padding: 12px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  &:hover {
    background-color: rgba(62, 39, 35, 0.08);
  }
`

const ArrayEditor = ({
  fields,
  showHeader,
  title,
  accessor = "",
  meta: { error, submitFailed },
  newField = ""
}) => {
  const getBlankField = () => {
    if (typeof newField === "function") {
      return newField()
    }
    return newField
  }

  return (
    <Grid>
      {showHeader && (
        <React.Fragment>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            onClick={() => fields.push(getBlankField())}
            title={`Add ${fields.name}`}
            aria-label={`Add ${fields.name}`}
          >
            <AddIcon />
          </IconButton>
        </React.Fragment>
      )}
      <DragDropContext onDragEnd={fieldArrayOnDrag(fields)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <FieldList ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((field, index) => (
                <Draggable key={field} draggableId={field} index={index}>
                  {(provided, snapshot) => (
                    <FieldItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      disableInteractiveElementBlocking
                    >
                      <Handle {...provided.dragHandleProps} aria-label="Drag">
                        <DragIndicatorIcon />
                      </Handle>
                      <Field
                        key=""
                        name={`${field}${accessor}`}
                        type="text"
                        className="short"
                        component={FormInput}
                        label="Field"
                      />
                      <IconButton
                        onClick={() => fields.remove(index)}
                        aria-label="Delete"
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </FieldItem>
                  )}
                </Draggable>
              ))}
            </FieldList>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  )
}

export default ArrayEditor
