import React, { useRef } from "react"
import { Button, Popover } from "@material-ui/core"
import { SketchPicker } from "react-color"

const ColorPicker = props => {
  const { anchorEl, onClose, onClick, color } = props
  const curColor = useRef(color)
  const open = Boolean(anchorEl)
  const id = open ? "color-popover" : undefined

  const onChange = (color, event) => {
    curColor.current = color
  }

  const handleClick = e => {
    onClick(curColor.current)
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <SketchPicker
        color={color}
        styles={{
          picker: {
            width: "200px",
            padding: "10px 10px 0px",
            boxSizing: "initial",
            background: "rgb(255, 255, 255)",
            borderRadius: "4px"
          }
        }}
        onChange={onChange}
      />
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={handleClick} color="secondary">
        OK
      </Button>
    </Popover>
  )
}

export default ColorPicker
