import React from "react"
import Html from "slate-html-serializer"
import colorString from "color-string"

import { BLOCK_TAGS, MARK_TAGS } from "lib/constants"

const rules = [
  {
    //special case for aligned paragraphs
    deserialize: (el, next) => {
      if (el.tagName.toLowerCase() == "p") {
        const align = el.style.getPropertyValue("text-align")
        if (align) {
          return {
            object: "block",
            type: "alignment",
            data: { align },
            nodes: next(el.childNodes)
          }
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.object == "block" && obj.type == "alignment") {
        const align = obj.data.get("align")
        return React.createElement(
          "p",
          { style: { textAlign: align } },
          children
        )
      }
    }
  },
  {
    //color spans
    deserialize: (el, next) => {
      if (el.tagName.toLowerCase() == "span") {
        const color = colorString.get.rgb(el.style.getPropertyValue("color"))
        const background = colorString.get.rgb(
          el.style.getPropertyValue("background-color")
        )
        if (color || background) {
          return {
            object: "mark",
            type: "color",
            data: {
              color,
              background
            },
            nodes: next(el.childNodes)
          }
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.object == "mark" && obj.type == "color") {
        const color = obj.data.get("color")
        const background = obj.data.get("background")
        if (color || background) {
          const style = {}
          if (color) style.color = colorString.to.rgb(color)
          if (background) style.backgroundColor = colorString.to.rgb(background)
          return React.createElement("span", { style }, children)
        }
      }
    }
  },
  {
    deserialize: (el, next) => {
      if (el.tagName.toLowerCase() == "ol") {
        return {
          object: "block",
          type: "ordered-list",
          nodes: next(el.childNodes)
        }
      }
      if (el.tagName.toLowerCase() == "ul") {
        return {
          object: "block",
          type: "unordered-list",
          nodes: next(el.childNodes)
        }
      }
      if (el.tagName.toLowerCase() == "li") {
        const nodes = next(el.childNodes).map(n => {
          if (n.object == "text") {
            return {
              object: "block",
              type: "list-item-child",
              nodes: [n]
            }
          }
          return n
        })

        return {
          object: "block",
          type: "list-item",
          nodes
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.type == "ordered-list") {
        return React.createElement("ol", null, children)
      }
      if (obj.type == "unordered-list") {
        return React.createElement("ul", null, children)
      }
      if (obj.type == "list-item") {
        return React.createElement("li", null, children)
      }
      if (obj.type == "list-item-child") {
        return <>{children}</> //should just be a  'text' object
      }
    }
  },
  {
    deserialize: (el, next) => {
      const block = BLOCK_TAGS.find(x => x.tag == el.tagName.toLowerCase())
      let childNodes = el.childNodes

      if (el.tagName.toLowerCase() == "pre") {
        const code = el.childNodes[0]
        if (code && code.tagName && code.tagName.toLowerCase() == "code") {
          childNodes = code.childNodes
        }
      }

      if (block) {
        return {
          object: "block",
          type: block.block,
          nodes: next(childNodes)
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.object == "block") {
        const block = BLOCK_TAGS.find(x => x.block == obj.type)
        if (block) return React.createElement(block.tag, null, children)
      }
    }
  },
  {
    deserialize(el, next) {
      const mark = MARK_TAGS.find(x => x.tag == el.tagName.toLowerCase())

      if (mark) {
        return {
          object: "mark",
          type: mark.mark,
          nodes: next(el.childNodes)
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.object == "mark") {
        const mark = MARK_TAGS.find(x => x.mark == obj.type)
        if (mark) return React.createElement(mark.tag, null, children)
      }
    }
  },
  {
    // Special case for images, to grab their src.
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === "img") {
        return {
          object: "block",
          type: "image",
          nodes: next(el.childNodes),
          data: {
            src: el.getAttribute("src")
          }
        }
      }
    }
  },
  {
    //mentions before links, since their html representatoin is also an anchor tag
    deserialize: (el, next) => {
      if (el.tagName.toLowerCase() == "a") {
        if (el.dataset.mention != null) {
          const id = el.dataset.id || el.getAttribute("href")
          const value = el.dataset.value || el.innerText

          return {
            object: "inline",
            type: "mention",
            nodes: next(el.childNodes),
            data: {
              id,
              value
            }
          }
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.object == "inline" && obj.type == "mention") {
        const id = obj.data.get("id")
        const value = obj.data.get("value")

        return (
          <a href={id} data-mention data-value={value}>
            {children}
          </a>
        )
      }
    }
  },

  {
    // Special case for links, to grab their href.
    deserialize: (el, next) => {
      if (el.tagName.toLowerCase() == "a") {
        return {
          object: "inline",
          type: "link",
          nodes: next(el.childNodes),
          data: {
            href: el.getAttribute("href"),
            target: el.getAttribute("target")
          }
        }
      }
    },
    serialize: (obj, children) => {
      if (obj.type == "link") {
        const href = obj.data.get("href")
        const target = obj.data.get("target")
        return React.createElement("a", { href, target }, children)
      }
    }
  }
]

const serializer = new Html({ rules })
export default serializer
