import React, { useEffect } from "react"
import { connect } from "react-redux"

import { loadPlanner, savePlanner } from "../../actions/plannerActions"

import Planner from "./Planner"
import PlannerConfig from "./PlannerConfig"

export const PlannerContainer = props => {
  const {
    match: {
      params: { id }
    },
    path = "",
    loading,
    planner,
    loadPlanner,
    loadError,
    apiError
  } = props

  useEffect(() => {
    if (loading || loadError || (planner && planner._id == id)) return
    loadPlanner(id)
  }, [loadPlanner, id, planner, loading, loadError])

  /* if(loading) {
        return (<div>Loading...</div>)
    }
*/
  if (loadError) {
    return <div>Invalid planner: {apiError}</div>
  }

  if (path == "config") {
    return <PlannerConfig {...props} />
  }

  return <Planner {...props} />
}

export default connect(
  s => ({
    planner: s.planner.current || {},
    loading: s.planner.isLoading,
    loadError: s.planner.plannerLoadFailure,
    error: s.planner.apiError
  }),
  { loadPlanner, savePlanner }
)(PlannerContainer)
