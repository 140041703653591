import styled from "styled-components"
import React from "react"
import { Typography } from "@material-ui/core"

const H = styled(Typography)`
  grid-column: 1 / -1;
`

const Header = ({ children }) => <H variant="h3">{children}</H>

export default Header
