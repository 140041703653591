import { createMuiTheme } from "@material-ui/core/styles"

// const theme = {}

// theme.palette = {
//   primary: ["#5680e9", "#84ceeb", "#5ab9ea", "#c1c8e4", "#8860d0"],
//   greyscale: ["#d8e0ff", "#c1c8e4", "#b1b7d1", "#a8aec7", "#6c7080"]
// }

// theme.reversePalette = reversePalette(theme.palette)

// theme.fonts = {
//   primary: "acumin-pro-semi-condensed",
//   inputs: "acumin-pro-condensed, sans-serif"
// }

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#6a4f4b",
      main: "#3e2723",
      dark: "#1b0000",
      contrastText: "#fff"
    },
    secondary: {
      light: "#38786a",
      main: "#004c3f",
      dark: "#002419",
      contrastText: "#fff"
    }
  },
  typography: { useNextVariants: true, fontFamily: "acumin-pro-semi-condensed" }
})

export default theme
