import React from "react"
import SelectedList from "./SelectList"
import { Typography } from "@material-ui/core"

const NounSelector = ({
  fields,
  nouns,
  label,
  meta: { error, submitFailed }
}) => {
  const localNouns = fields.value || []
  const onClick = id => {
    const index = localNouns.indexOf(id)
    if (index == -1) {
      fields.push(id)
    } else {
      fields.remove(index)
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6">{label}</Typography>

      <SelectedList
        className="field"
        items={nouns}
        selectedList={localNouns}
        onClick={onClick}
      />
    </React.Fragment>
  )
}

export default NounSelector
