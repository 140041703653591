import React from "react"
import { savePlanner } from "../../actions/plannerActions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Card from "components/shared/Card"
import { FlexTitle } from "components/shared/styled"
import styled from "styled-components"

import PlannerForm from "./PlannerForm"
import { Typography } from "@material-ui/core"

const ModalPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 1001;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Modal = ({ children }) => (
  <ModalContainer>
    <Overlay />
    <ModalPanel>{children}</ModalPanel>
  </ModalContainer>
)

const FormContainer = styled.div`
  width: 33vw;
`

export const PlannerAdd = ({ savePlanner, history }) => {
  const submit = async planner => {
    const { payload } = await savePlanner(planner)
    history.push(`/planner/config/${payload._id}`)
  }

  return (
    <Modal>
      <Card
        title={
          <FlexTitle>
            <Typography variant="h5">Add Planner</Typography>
            <Link to="/planner">✖</Link>
          </FlexTitle>
        }
      >
        <FormContainer>
          <PlannerForm submit={submit} showHelp />
        </FormContainer>
      </Card>
    </Modal>
  )
}

export default connect(
  s => ({}),
  { savePlanner }
)(PlannerAdd)
