import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import { Form, Field } from "react-final-form"
import arrayMutators from "final-form-arrays"

import {
  savePlanner,
  getNounsForPlannerTemplates,
  getNounsForNounTemplates
} from "../../actions/plannerActions"
import { listNounTemplates } from "../../actions/templateNounActions"
import { listPlannerTemplates } from "../../actions/templatePlannerActions"

import { Layout } from "components/shared/styled"
import PlannerConfigCard from "./PlannerConfigCard"

import PlannerSelect from "./PlannerSelect"
import NounSelect from "../nouns/NounSelect"
import NounItem from "../nouns/NounItem"
import SectionItem from "../sections/SectionItem"

const emptySection = () => ({
  key: uniqid(),
  name: "New Section",
  desc: "New Section Description",
  fields: []
})

export const PlannerConfig = props => {
  console.log("PlannerConfig")
  const { planner, ptList, ntList } = props
  const { savePlanner, listNounTemplates, listPlannerTemplates } = props

  const [templatePanel, setTemplatePanel] = useState("")
  const [nounPanel, setNounPanel] = useState("")
  const [sectionPanel, setSectionPanel] = useState("")

  const cardActions = {
    templatePanel: template => {
      setTemplatePanel(template)
    },
    editNoun: noun => {
      setNounPanel(noun)
      setTemplatePanel("")
      setSectionPanel("")
    },
    editSection: section => {
      const noun = section.split(".", 2)[0]
      if (noun != nounPanel) setNounPanel("")
      setSectionPanel(section)
      setTemplatePanel("")
    },
    checkClose: (panel, name) => {
      console.log("checkClose", panel, name)
      if (panel == "noun" && name == nounPanel) {
        setNounPanel("")
        setSectionPanel("")
      }
      if (panel == "section" && name == sectionPanel) setSectionPanel("")
    }
  }

  useEffect(() => {
    if (ptList && ptList.length) return
    listPlannerTemplates()
  }, [ptList, listPlannerTemplates])

  useEffect(() => {
    if (ntList && ntList.length) return
    listNounTemplates()
  }, [ntList, listNounTemplates])

  return (
    <Form
      onSubmit={savePlanner}
      initialValues={planner}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit, ...rest }) => (
        <form onSubmit={handleSubmit}>
          <Layout cols={3} noHeader>
            <PlannerConfigCard {...rest} {...cardActions} />
            {templatePanel == "planner" && (
              <PlannerSelect
                items={ptList}
                onConfirm={selected =>
                  getNounsForPlannerTemplates(selected)
                    .then(n => n.map(x => rest.form.mutators.push("nouns", x)))
                    .then(() => setTemplatePanel(""))
                    .catch(err => console.error(err))
                }
              />
            )}
            {templatePanel == "noun" && (
              <NounSelect
                items={ntList}
                onConfirm={selected =>
                  getNounsForNounTemplates(selected)
                    .then(n => n.map(x => rest.form.mutators.push("nouns", x)))
                    .then(() => setTemplatePanel(""))
                    .catch(err => console.error(err))
                }
              />
            )}
            {nounPanel && (
              <Field
                name={nounPanel}
                {...cardActions}
                component={NounItem}
                form={rest.form}
                onAddSection={() => {
                  const struct = `${nounPanel}.struct`
                  rest.form.mutators.push(struct, emptySection())
                  const parentVal = rest.form.getFieldState(nounPanel).value

                  const len = parentVal.struct.length
                  cardActions.editSection(`${struct}[${len - 1}]`)
                }}
              />
            )}
            {sectionPanel && (
              <Field
                name={sectionPanel}
                {...cardActions}
                component={SectionItem}
              />
            )}
          </Layout>
        </form>
      )}
    />
  )
}

export default connect(
  s => ({
    ptList: s.templatePlanners.list,
    ntList: s.templateNouns.list
  }),
  {
    savePlanner,
    listNounTemplates,
    listPlannerTemplates
  }
)(PlannerConfig)
