import React from "react"
import { Field } from "react-final-form"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@material-ui/core"
import CardTitle from "components/shared/CardTitle"
import { FlexTitle } from "components/shared/styled"
import { FormInput } from "components/shared/form"

import SectionList from "../sections/SectionList"
const NounItem = ({
  input,
  editNoun,
  editSection,
  checkClose,
  onAddSection,
  form
}) => (
  <Card
    style={{
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 3fr 8fr 1fr",
      height: "calc(100vh - 64px - 2rem)"
    }}
  >
    <CardTitle>
      <FlexTitle>
        <Typography variant="h5" color="inherit">
          Noun Edit
        </Typography>
        <Button
          type="button"
          variant="text"
          color="inherit"
          onClick={() => editNoun("")}
        >
          Done
        </Button>
      </FlexTitle>
    </CardTitle>
    <CardContent>
      <Field
        name={`${input.name}.name`}
        label="Name"
        component={FormInput}
        type="text"
      />

      <Field
        name={`${input.name}.desc`}
        label="Description"
        component={FormInput}
        type="textarea"
      />
    </CardContent>
    <CardContent style={{ maxHeight: "100%", overflow: "auto" }}>
      <SectionList
        noun={input.name}
        onEdit={editSection}
        checkClose={checkClose}
        form={form}
        edit
      />
    </CardContent>
    <CardActions>
      <Button onClick={onAddSection} color="secondary">
        New Section
      </Button>
    </CardActions>
  </Card>
)

export default NounItem
