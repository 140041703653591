import styled from "styled-components"

const FlexTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & a {
    display: block;

    text-decoration: none;
    color: black;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`
FlexTitle.displayName = "FlexTitle"

export default FlexTitle
