import React from "react"
import { Button, Popover } from "@material-ui/core"
import { Form, Field } from "react-final-form"
import styled from "styled-components"

import FormInput from "./FormInput"

const FormContainer = styled.div`
  padding: 0.5rem;
`

const LinkDialog = props => {
  const { anchorEl, onClose, onSubmit, onRemove, url } = props
  const open = Boolean(anchorEl)
  const id = open ? "color-popover" : undefined

  const initValues = { url }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initValues}
        render={({ handleSubmit, pristine, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <Button onClick={onRemove}>Remove Link</Button>
              <Field
                name="url"
                component={FormInput}
                label="URL"
                type="text"
                fullWidth
              />

              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" color="secondary">
                Add Link
              </Button>
            </FormContainer>
          </form>
        )}
      />
    </Popover>
  )
}

export default LinkDialog
