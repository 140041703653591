import React from 'react'
import styled from 'styled-components'
import { palette } from 'styled-theme'

const StyledButton = styled.button`
    white-space: nowrap;
    display: inline-block;
    border-radius: 5px;
    padding: 5px 10px;
    font-size:  16px;
    min-width: 36px;
    color:${palette("grayscale",0)};
    font-weight:600;
    background-image: linear-gradient(to right, ${palette("primary",2)}, ${palette("primary",3)});
    border: 1px solid ${palette("primary",0)};
    box-shadow:0 3px 0 0 rgba(0,0,0,25%);
    cursor:pointer;
    &[disabled] {
        opacity:0.4;
        cursor:not-allowed;
    }
    &:hover {
        background-image: linear-gradient(to right, ${palette("primary",3)}, ${palette("primary",4)});
    }
    &:active {
        box-shadow:none;
    }
`
StyledButton.displayName = 'StyledButton'

const Button = ({children, click, ...rest }) => (
    <StyledButton {...rest} onClick={click}>{children}</StyledButton>
)


export default Button