import React, { useState } from "react"
import styled from "styled-components"
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse
} from "@material-ui/core"

const ClippedDrawer = styled(Drawer)`
  & > div {
    position: static;
  }
`
const NestList = styled(List)`
  padding-left: 1rem;
`

const ExpandableMenu = ({ label, links, history }) => {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ListItem button key={label}>
        <ListItemText
          primary={label}
          onClick={() => setOpen(!open)}
          primaryTypographyProps={{ variant: "h6" }}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <NestList disablePadding>
          {links.map(x => (
            <ListItem button key={x.to} onClick={() => history.push(x.to)}>
              <ListItemText primary={x.label} />
            </ListItem>
          ))}
        </NestList>
      </Collapse>
    </React.Fragment>
  )
}

const adminMenu = [
  {
    label: "System",
    links: [
      {
        label: "Users",
        to: "/admin/users"
      },
      {
        label: "News",
        to: "/admin/news"
      }
    ]
  },
  {
    label: "Templates",
    links: [
      {
        label: "Planners",
        to: "/admin/template/planners"
      },
      {
        label: "Nouns",
        to: "/admin/template/nouns"
      },
      {
        label: "Sections",
        to: "/admin/template/sections"
      }
    ]
  }
]

const AdminSidebar = ({ path, history }) => (
  <ClippedDrawer variant="permanent">
    {adminMenu.map(x => (
      <ExpandableMenu {...x} key={x.label} history={history} />
    ))}
  </ClippedDrawer>
)

export default AdminSidebar
