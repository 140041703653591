import React from "react"
import { Switch, Route } from "react-router"
import styled from "styled-components"

import AdminSidebar from "./AdminSidebar"
import NotFound from "components/shared/NotFound"
import UserList from "./user/UserList"
import SectionTemplateList from "./templateSection/TemplateSectionList"
import NounTemplateList from "./templateNoun/TemplateNounList"
import PlannerTemplateList from "./templatePlanner/PlannerTemplateList"
import AdminHome from "./AdminHome"
import NewsList from "./news/NewsList"

const AdminGrid = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-gap: 1rem;
`
const ContentBlock = styled.div`
  padding-top: 0.5rem;
  padding-right: 1rem;
`

const Admin = ({ match, history }) => (
  <AdminGrid>
    <AdminSidebar path={match.path} history={history} />
    <ContentBlock>
      <Switch>
        <Route path={match.path} exact component={AdminHome} />
        <Route path={`${match.path}/users`} component={UserList} />
        <Route path={`${match.path}/news`} component={NewsList} />
        <Route
          path={`${match.path}/template/sections`}
          component={SectionTemplateList}
        />
        <Route
          path={`${match.path}/template/nouns`}
          component={NounTemplateList}
        />
        <Route
          path={`${match.path}/template/planners`}
          component={PlannerTemplateList}
        />
        <Route component={NotFound} />
      </Switch>
    </ContentBlock>
  </AdminGrid>
)

export default Admin
