import React, { useState, useRef, useEffect } from "react"
import { Editor } from "slate-react"
import { Value } from "slate"
import { InputLabel } from "@material-ui/core"

import styled from "styled-components"
import Toolbar from "./Toolbar"

import { BLOCK_TAGS, MARK_TAGS } from "lib/constants"
import { scrollbars } from "lib/styles"

import simpleMark from "./plugins/simpleMark"
import simpleBlock from "./plugins/simpleBlock"
import formatList from "./plugins/formatList"
import listPlugin from "./plugins/list"
import colorPlugin from "./plugins/color"
import linkPlugin from "./plugins/link"
import mentionPlugin from "./plugins/mentions"

import serializer from "lib/serializer"

const Container = styled.div`
  position: relative;
  &:hover::before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  }

  &::before {
    content: "\\00a0";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  ::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #1b0000;
    pointer-events: none;
  }
`

const StyledEditor = styled(Editor)`
  max-height: 20rem;
  overflow-y: auto;
  ${scrollbars}
`

const marks = MARK_TAGS.map(x => simpleMark(x))
const blocks = BLOCK_TAGS.filter(x => x.simple).map(x => simpleBlock(x))
const formatMenu = formatList()
const list = listPlugin()
const color = colorPlugin()
const link = linkPlugin()
const mention = mentionPlugin()
const getPlugins = () => {
  return marks
    .map(x => x.plugin)
    .concat([
      formatMenu.plugin,
      list.plugin,
      color.plugin,
      link.plugin,
      mention.plugin
    ])
    .concat(blocks.map(x => x.plugin))
}

const getButtons = () => {
  return marks.concat(blocks).concat([formatMenu, list, color, link])
}

const plugins = getPlugins()
const buttons = getButtons()

const getValue = html => {
  const v = html || "<p></p>"
  const theValue = serializer.deserialize(v)
  return theValue
}

const initValue = html => {
  const currentValue = getValue(html)
  //might want to update the selection to put us at the end?
  console.log("Initial valuing")
  return currentValue
}
const Editable = ({ input, onChange, mentions = [] }) => {
  const { name, value, ...restInput } = input
  const [val, setVal] = useState(() => {
    console.log("Running", value)
    return initValue(value)
  })

  useEffect(() => {
    const currentHtml = serializer.serialize(val)
    if (value != currentHtml) {
      onChange(currentHtml)
    }
  }, [val, onChange])

  const focus = useRef(false)
  const editor = useRef(null)

  const change = chg => {
    setVal(chg.value)
  }

  let timeoutID = null

  const onBlur = () => {
    timeoutID = setTimeout(() => {
      if (focus.current) {
        focus.current = false
      }
    }, 0)
  }

  const onFocus = () => {
    clearTimeout(timeoutID)
    if (!focus.current) {
      focus.current = true
    }
  }

  //balancing creating plugins outside of render, vs side effects.
  //mentions should be pretty static, and there should be only one set of them ever on a single page
  useEffect(() => {
    mention.setMentions(mentions)
  }, [mentions])

  return (
    <Container onBlur={onBlur} onFocus={onFocus}>
      {focus.current && (
        <Toolbar editor={editor.current} buttons={buttons} val={val} />
      )}
      <StyledEditor
        autoFocus
        value={val}
        onChange={change}
        plugins={plugins}
        ref={editor}
      />
    </Container>
  )
}

export default Editable
