import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import {palette} from 'styled-theme'

const Content = styled.div`
    position:relative;
    padding:.5rem 2rem .5rem 2rem;
    background-color: ${props => props.selected ? palette('greyscale',1) : palette('grayscale',5)};
    cursor:pointer;
    border-radius:.6rem;
    &:hover {
        background-color: ${palette('greyscale',0)};
    }
    &::before {
        content: '${props => props.selected ? '✓':''}';
        width: 1rem;
        color: green;
        position: absolute;
        left: .5rem;
        font-weight: bold;
        font-size: 2rem;
        display: grid;
        align-content: center;
        justify-content: center;
    }
`
const Name = styled.header`
    font-size:1rem;
    font-weight:bold;
    grid-column: 1/-1;
    color:${palette('greyscale',0,true)};
    border-bottom:1px solid ${palette('greyscale',0,true)};
    text-shadow:0 3px 8px rgba(0,0,0,.16);
`

const SelectItem = ({selected, item :{_id, name, desc}, onClick}) => (
    <Content selected={selected?1:0} onClick={()=>onClick(_id)}>
        <Name>{name}</Name>
        <div dangerouslySetInnerHTML={{__html: desc}}></div>
    </Content>
)

SelectItem.propTypes = {
    item: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        desc: PropTypes.string
    }),
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired
}


export default SelectItem