import React, { useEffect } from "react"
import NavBar from "./components/shared/NavBar"
import { connect } from "react-redux"
import styled from "styled-components"
import { getCurrentUser, loginResponse } from "./actions/userActions"
import { Switch, Route } from "react-router"
import GlobalStyle from "./lib/styles"
import Admin from "./components/admin/Admin"
import Home from "./components/Home"
import PlannerContainer from "./components/planners/PlannerContainer"
import { useAuth0 } from "./Auth0Provider"

const AppGrid = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100vh;
  width: 100vw;
  grid-gap: 1rem;
`

export const App = ({ localuser, isAuth, getCurrentUser }) => {
  const { isAuthenticated, user } = useAuth0()
  useEffect(() => {
    if (isAuthenticated && user) getCurrentUser(user)
  }, [isAuthenticated, getCurrentUser, user])

  const Nav = props => <NavBar localuser={localuser} />

  return (
    <AppGrid>
      <GlobalStyle />
      <Switch>
        <Route path={`/planner/config/:id`} render={Nav} />
        <Route
          path={[`/planner/:id/:item`, `/planner/:id`]}
          render={props => ""}
        />
        <Route render={Nav} />
      </Switch>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route
          path={`/planner/config/:id`}
          render={props => <PlannerContainer path="config" {...props} />}
        />
        <Route
          path={[`/planner/:id/:item`, `/planner/:id`]}
          component={PlannerContainer}
        />
        <Route render={props => <Home isAuth={isAuth} {...props} />} />
      </Switch>
    </AppGrid>
  )
}

export default connect(
  s => ({
    localuser: s.user.currentUser,
    isAuth: s.user.isAuthenticated
  }),
  { loginResponse, getCurrentUser }
)(App)
