import { API_BASE, NT_LOAD, NT_LIST, NT_SAVE } from "lib/constants"
import { RSAA } from "redux-api-middleware"
import { push } from "connected-react-router"
const path = "/admin/template/nouns"
const nonadmin = "/templates/nouns"

export const listNounTemplates = () => ({
  [RSAA]: {
    endpoint: `${API_BASE}${nonadmin}`,
    method: "GET",
    types: [NT_LIST.REQUEST, NT_LIST.SUCCESS, NT_LIST.FAILURE]
  }
})

export const loadNounTemplate = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}`,
    method: "GET",
    types: [NT_LOAD.REQUEST, NT_LOAD.SUCCESS, NT_LOAD.FAILURE]
  }
})

export const saveNounTemplate = template => {
  return async (dispatch, getState) => {
    let endpoint = `${API_BASE}${path}`
    let method = "POST"
    if (template._id) {
      endpoint = `${endpoint}/${template._id}`
      method = "PUT"
    }

    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method,
        body: JSON.stringify(template),
        headers: {
          "content-type": "application/json"
        },
        types: [NT_SAVE.REQUEST, NT_SAVE.SUCCESS, NT_SAVE.FAILURE]
      }
    })

    if (actionResponse.error) return actionResponse

    return dispatch(push(`${path}`))
  }
}
