import React, { useState, useRef } from "react"
import { IconButton } from "@material-ui/core"
import colorString from "color-string"
import ColorPicker from "../../ColorPicker"

//colorstring rgba ([r,g,b,a] to react-color rgba {r, g,b,a})
const cs2rc = ([r, g, b, a]) => ({
  r,
  g,
  b,
  a
})
const rc2cs = ({ r, g, b, a }) => [r, g, b, a]

const renderMark = ({ attributes, children, mark }, editor, next) => {
  if (mark.type != "color") return next()

  const color = mark.data.get("color")
  const background = mark.data.get("background")
  if (color || background) {
    const style = attributes.style || {}
    if (color) style.color = colorString.to.rgb(color)
    if (background) style.backgroundColor = colorString.to.rgb(background)
    attributes.style = style
  }

  return <span {...attributes}>{children}</span>
}

const color = () => {
  const plugin = {
    renderMark
  }

  const single = (aria, icon, isActive, color = [1, 1, 1, 1], onClick) => {
    return (
      <IconButton
        className={`slate-button ${isActive && "active"}`}
        style={{ color: colorString.to.rgb(color) }}
        aria-label={aria}
        size="small"
        onClick={onClick}
      >
        {icon}
      </IconButton>
    )
  }
  const defBG = [255, 2552, 255, 1]
  const defFG = [0, 0, 0, 1]

  const Button = (editor, value, icons) => {
    const [colorType, setType] = useState("")
    const [anchorEl, setAnchorEl] = useState(null)
    const [startColor, setStartColor] = useState(null)
    const lastBGColor = useRef(null)
    const lastFGColor = useRef(null)

    const openPicker = (event, type) => {
      setType(type)
      setAnchorEl(event.currentTarget)
      if (type == "foreground") {
        lastFGColor.current
          ? setStartColor(cs2rc(lastFGColor.current))
          : setStartColor(cs2rc(defFG))
      } else {
        lastBGColor.current
          ? setStartColor(cs2rc(lastBGColor.current))
          : setStartColor(cs2rc(defBG))
      }
    }

    const handleClose = () => {
      setAnchorEl(null)
      setType("")
    }

    const foreground = event => openPicker(event, "foreground")
    const background = event => openPicker(event, "background")

    const mark = value.activeMarks.find(x => x.type == "color")
    if (mark) {
      lastFGColor.current = mark.data.get("color")
      lastBGColor.current = mark.data.get("background")
    }
    const isActive = !!mark

    const onClick = ({ rgb }) => {
      const c = rc2cs(rgb)
      let color = defFG,
        background = defBG
      if (mark) {
        color = mark.data.get("color")
        background = mark.data.get("background")
        editor.removeMark(mark)
      }

      if (colorType == "foreground") {
        color = c
      } else {
        background = c
      }

      editor.addMark({
        type: "color",
        data: {
          color,
          background
        }
      })

      handleClose()
    }
    return (
      <>
        {single(
          "Foreground Color",
          icons[0],
          isActive,
          lastFGColor.current,
          foreground
        )}
        {single(
          "BackgroundColor",
          icons[1],
          isActive,
          lastBGColor.current,
          background
        )}
        <ColorPicker
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={onClick}
          color={startColor}
        />
      </>
    )
  }

  return {
    plugin,
    key: "color",
    button: Button
  }
}

export default color
