import React, { useState } from "react"
import isUrl from "is-url"
import { IconButton } from "@material-ui/core"
import LinkDialog from "../../LinkDialog"

const isActiveQuery = "isLinkActive",
  wrapCommand = "wrapLink",
  unwrapCommand = "unwrapLink",
  key = "link"

const link = () => {
  const renderInline = (props, editor, next) => {
    const { node, attributes, children } = props

    if (node.type != key) return next()

    return (
      <a
        href={node.data.get("href")}
        target={node.data.get("target") || "_blank"}
        {...attributes}
      >
        {children}
      </a>
    )
  }

  const isLinkActive = (e, value) => {
    const { inlines } = value
    const active = inlines.some(i => i.type == key)
    return active
  }

  const plugin = {
    renderInline,
    onCommand: (command, change, next) => {
      const { type, args } = command
      const { value } = change
      const { selection } = value
      const { isCollapsed, start } = selection
      let url

      if (
        (type === "insertText" && isUrl((url = args[0]))) ||
        (type === "insertFragment" && isUrl((url = args[0].text)))
      ) {
        // If there is already a link active, unwrap it so that we don't end up
        // with a confusing overlapping inline situation.
        if (change.query(isActiveQuery, value)) {
          change.command(unwrapCommand)
        }

        // If the selection is collapsed, we need to allow the default inserting
        // to occur instead of just wrapping the existing text in a link.
        if (isCollapsed) {
          next()
          change
            .moveAnchorTo(start.offset)
            .moveFocusTo(start.offset + url.length)
        }

        // Wrap the selection in a link, and collapse to the end of it.
        change.command(wrapCommand, url).moveToEnd()
        return
      }

      next()
    },
    commands: {
      wrapLink: (editor, href) =>
        editor.wrapInline({ type: key, data: { href, target: "_blank" } }),
      unwrapLink: editor => editor.unwrapInline(key)
    },
    queries: {
      isLinkActive
    }
  }

  const Button = (editor, value, icon) => {
    const [anchorEl, setAnchorEl] = useState(null)
    let url = ""
    let link = ""

    const active = isLinkActive(null, value)

    if (active) {
      const linkNode = value.inlines.find(i => i.type == key)
      url = linkNode.data.get("href")
      link = linkNode.text
    }

    const onClick = e => {
      setAnchorEl(e.target)
    }
    const onSubmit = ({ url }) => {
      if (active) {
        editor
          .setInlines({ type: key, data: { href: url, target: "_blank" } })
          .focus()
      } else if (value.selection.isExpanded) {
        editor.command(wrapCommand, url).focus()
      } else {
        editor
          .insertText(url)
          .moveFocusBackward(url.length)
          .command(wrapCommand, url)
          .focus()
      }
      handleClose()
    }

    const onRemove = e => {
      editor.command(unwrapCommand).focus()
      handleClose()
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <IconButton
          className={`slate-button ${active && "active"}`}
          aria-label={key}
          size="small"
          onClick={onClick}
        >
          {icon}
        </IconButton>
        <LinkDialog
          anchorEl={anchorEl}
          onClose={handleClose}
          onSubmit={onSubmit}
          onRemove={onRemove}
          url={url}
          link={link}
        />
      </>
    )
  }

  return {
    plugin,
    button: Button,
    key
  }
}

export default link
