import React from "react"
import styled from "styled-components"
import { palette } from "styled-theme"

const SimpleInput = styled.input`
  border: none;
  font: inherit;
  color: inherit;
  display: inline-block;
  outline: 0;
  padding: 2px;
  &:hover {
    background: ${palette("greyscale", 0)};
    cursor: text;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
    cursor: text;
  }
`

const Input = ({
  input,
  className,
  placeholder = "",
  type = "text",
  style
}) => (
  <SimpleInput
    {...input}
    size={Math.max(20, input.value.length + 1)}
    style={style}
    className={`field ${className}`}
    type={type}
    placeholder={placeholder}
  />
)

export default Input
