import styled from "styled-components"
import { palette } from "styled-theme"

const FormGrid = styled.div`
     display:grid;
     grid-template-columns:
     repeat(2,minmax(min-content,auto)) 1fr repeat(2,minmax(min-content,auto));
     padding:1rem;
     background: ${palette("grayscale", 5)};
     border-radius: 10px;
     grid-gap:.5rem;
     & label {
         text-align:right;
         font-weight:bold;
         padding:.5rem;
         &::after {
             content:':';
        }
     }
     & .buttons {
         grid-column:1/-3;
         justify-self:end;
     }
     & input[type=checkbox] {
         align-self: center;
     }
     & header {
        grid-column: 1/-1;
        justify-self:center;
        align-self:center;
        span {
            font-weight: bold;
            padding: .5rem;
        }
     }
     .field {
         grid-column: span 2;
         &+label {
             grid-column-start:1
         }
        &.short {

             grid-column: span 1;
         }
     }
    }

`
FormGrid.displayName = "FormGrid"

export default FormGrid
