import React from "react"
import { FormSpy } from "react-final-form"

////////////////////////////////////////////////////////////
// Usage
//
//  <AutoSave change={1000} save={saveAction} />
//
// debounce is in ms
// saveAction is whatever you were pasing to Form onSubmit
//            and should return a promise
//
////////////////////////////////////////////////////////////
export default props => (
  <FormSpy
    {...props}
    subscription={{ dirty: true, values: true }}
    onChange={s => {
      console.log(s)
      if (s.dirty) {
        const newState = {
          isDirty: s.dirty,
          ...s.values
        }
        //props.change(newState)
      }
    }}
  />
)
