import React from "react"
import { Card as MuiCard, CardContent } from "@material-ui/core"
import CardTitle from "./CardTitle"
import styled from "styled-components"

const StyledMuiCard = styled(MuiCard)`
  margin-bottom: 1rem;
`
const StyledCardContent = styled(CardContent)`
  overflow-y: auto;
`
const Card = ({ children, title }) => (
  <StyledMuiCard>
    {title && <CardTitle>{title}</CardTitle>}
    <StyledCardContent style={{ position: "relative" }}>
      {children}
    </StyledCardContent>
  </StyledMuiCard>
)

export default Card
