import styled from "styled-components"

const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols || 2}, 1fr);
  grid-template-rows: ${({ noHeader }) =>
      !noHeader && "minmax(1rem, max-content)"} 1fr;
  grid-auto-rows: minmax(min-content, 1fr);
  padding: 0 1rem;
  grid-gap: 1rem;
  height: calc(100% - 1.5rem);
`
Layout.displayName = "Layout"

export default Layout
