import React, { useEffect } from "react"
import { connect } from "react-redux"
import MaterialTable, { MTableToolbar } from "material-table"
import { Switch, Route } from "react-router"
import styled from "styled-components"
import { Button } from "@material-ui/core"

import { Layout } from "components/shared/styled"
import {
  listSectionTemplates,
  saveSectionTemplate
} from "../../../actions/templateSectionActions"

import SectionTemplateForm from "./SectionTemplateForm"

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
`

export const TemplateSectionList = props => {
  const { list, loading, history, match } = props
  const { listSectionTemplates, saveSectionTemplate } = props

  useEffect(() => {
    listSectionTemplates()
  }, [listSectionTemplates])

  const data = list.map(x => ({ ...x, strFields: JSON.stringify(x.fields) }))

  return (
    <Layout>
      <MaterialTable
        title="Section Templates"
        data={data}
        columns={[
          {
            title: "Name",
            field: "name"
          },
          {
            title: "Key",
            field: "key"
          },
          {
            title: "Description",
            field: "desc"
          },
          {
            title: "Fields",
            field: "strFields"
          }
        ]}
        onRowClick={(event, rowData) =>
          history.push(`${match.path}/${rowData._id}`)
        }
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <ButtonBar>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`${match.path}/add`)}
                >
                  Add Section Template
                </Button>
              </ButtonBar>
            </div>
          )
        }}
        options={{ sorting: true, pageSize: 10 }}
      />

      <Switch>
        <Route
          path={`${match.path}/add`}
          component={() => {
            if (loading) return <div>Loading...</div>
            return <SectionTemplateForm submit={saveSectionTemplate} />
          }}
        />
        <Route
          path={`${match.path}/:id`}
          component={({ match }) => {
            const current = list.find(x => x._id == match.params.id)
            if (loading || !current) return <div>Loading...</div>
            return (
              <SectionTemplateForm
                submit={saveSectionTemplate}
                section={current}
              />
            )
          }}
        />
      </Switch>
    </Layout>
  )
}
export default connect(
  s => ({
    list: s.templateSections.list,
    loading: s.templateSections.isLoading
  }),
  { listSectionTemplates, saveSectionTemplate }
)(TemplateSectionList)
