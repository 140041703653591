import React, { useState } from "react"
import { Field } from "react-final-form"
import uniqid from "uniqid"
import { FieldArray } from "react-final-form-arrays"
import styled from "styled-components"
import {
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { FlexTitle } from "components/shared/styled"
import { fieldArrayOnDrag } from "../../utils/form-uitls"

const NestList = styled(List)`
  padding-left: 1rem;
`
const NestTitle = styled(Typography)`
  padding-left: 1rem;
`

const ExpandableItem = ({
  input,
  index,
  editMode,
  onDelete,
  onAddField,
  onEdit,
  edit,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  if (editMode && open) setOpen(false)
  const section = input.value
  return (
    <Draggable
      draggableId={input.name}
      index={index}
      isDragDisabled={!editMode}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <ListItem button>
            {editMode ? (
              <ListItemIcon {...provided.dragHandleProps}>
                <DragIndicatorIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon onClick={() => setOpen(!open)}>
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            )}
            <ListItemText
              primary={section.name}
              secondary={section.desc}
              onClick={() => !editMode && setOpen(!open)}
            />
            {edit && (
              <ListItemSecondaryAction>
                {editMode ? (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={onEdit}>
                    <EditIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <NestTitle component="div" variant="subtitle1" color="secondary">
              Fields
            </NestTitle>
            <NestList disablePadding>
              {section.fields &&
                section.fields.map(x => (
                  <ListItem
                    key={`${input.name}.${section.key}.${x.name}`}
                    button
                  >
                    <ListItemText primary={x.name} secondary={x.desc} />
                  </ListItem>
                ))}
            </NestList>
          </Collapse>
        </div>
      )}
    </Draggable>
  )
}

const emptyField = () => ({
  id: uniqid(),
  name: "New field"
})

const SectionList = ({ noun, onEdit, checkClose, edit, form }) => {
  const [editMode, setEditMode] = useState(false)
  const push = form.mutators.push
  return (
    <FieldArray name={`${noun}.struct`}>
      {({ fields }) => (
        <DragDropContext onDragEnd={fieldArrayOnDrag(fields)}>
          <Droppable droppableId={`${noun}.struct`} isDropDisabled={!editMode}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <FlexTitle>
                  <NestTitle component="div" variant="h6" color="secondary">
                    Sections
                  </NestTitle>
                  {edit && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editMode}
                          onChange={() => setEditMode(!editMode)}
                          value="editMode"
                        />
                      }
                      label="Edit"
                      labelPlacement="start"
                    />
                  )}
                </FlexTitle>
                {fields.length ? (
                  <NestList disablePadding>
                    {fields.map((name, index) => (
                      <Field
                        key={name}
                        name={name}
                        edit={edit}
                        index={index}
                        editMode={editMode}
                        component={ExpandableItem}
                        onEdit={() => onEdit(name)}
                        onDelete={() => {
                          fields.remove(index)
                          checkClose("section", name)
                        }}
                        onAddField={() => push(`${name}.fields`, emptyField())}
                      />
                    ))}
                  </NestList>
                ) : (
                  <Typography component="div">
                    There are no sections on this noun!
                    <br />
                    Add a section template some sections and start creating.
                  </Typography>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>
  )
}

export default SectionList
