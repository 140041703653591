import React from "react"
import { Select, MenuItem } from "@material-ui/core"

const formatting_menu = [
  { tag: "p", block: "paragraph", label: "Normal" },
  { tag: "h1", block: "heading-one", label: "h1" },
  { tag: "h2", block: "heading-two", label: "h2" },
  { tag: "h3", block: "heading-three", label: "h3" },
  { tag: "h4", block: "heading-four", label: "h4" },
  { tag: "h5", block: "heading-five", label: "h5" },
  { tag: "h6", block: "heading-six", label: "h6" }
]

const FormatMenu = (editor, value, _) => {
  const def = formatting_menu[0]
  const active = type => value.blocks.some(node => node.type == type)

  const setStyle = type =>
    editor.setBlocks(active(type) ? def.block : type).focus()
  let format = def
  if (value.startBlock) {
    const b = formatting_menu.find(x => x.block == value.startBlock.type)
    if (b) format = b
  }
  return (
    <Select
      value={format.block}
      onChange={({ target }) => {
        setStyle(target.value)
      }}
    >
      {formatting_menu.map(x => (
        <MenuItem key={x.block} value={x.block}>
          {x.label}
        </MenuItem>
      ))}
    </Select>
  )
}

const formatList = () => {
  const renderBlock = (props, editor, next) => {
    const block = formatting_menu.find(x => x.block == props.node.type)
    if (!block) return next()
    return React.createElement(block.tag, props.attributes, props.children)
  }

  const plugin = {
    renderBlock
  }

  return {
    plugin,
    button: FormatMenu,
    key: "formatList"
  }
}

export default formatList
