import React, { useState } from "react"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import uniqid from "uniqid"

import {
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Typography
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { FlexTitle } from "components/shared/styled"
import SectionList from "../sections/SectionList"
import { fieldArrayOnDrag } from "../../utils/form-uitls"

const emptySection = () => ({
  key: uniqid(),
  name: "New Section",
  desc: "New Section Description",
  fields: []
})

const ExpandableItem = ({
  input,
  index,
  editMode,
  onDelete,
  onEdit,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const noun = input.value //just display for now
  if (editMode && open) setOpen(false)
  return (
    <Draggable
      draggableId={input.name}
      index={index}
      isDragDisabled={!editMode}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <ListItem button component="div">
            {editMode ? (
              <ListItemIcon {...provided.dragHandleProps}>
                <DragIndicatorIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon onClick={() => setOpen(!open)}>
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            )}
            <ListItemText
              primary={noun.name}
              secondary={noun.desc}
              primaryTypographyProps={{
                variant: "h5"
              }}
              onClick={() => !editMode && setOpen(!open)}
            />
            <ListItemSecondaryAction>
              {editMode ? (
                <IconButton onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={!editMode && open} timeout="auto" unmountOnExit>
            <SectionList noun={input.name} form={props.form} />
          </Collapse>
        </div>
      )}
    </Draggable>
  )
}

const NounList = ({ onEdit, editSection, checkClose, form }) => {
  const [editMode, setEditMode] = useState(false)
  const getFieldState = form.getFieldState
  const push = form.mutators.push

  return (
    <FieldArray name="nouns">
      {({ fields }) => (
        <DragDropContext onDragEnd={fieldArrayOnDrag(fields)}>
          <Droppable droppableId="nouns" isDropDisabled={!editMode}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <FlexTitle>
                  <Typography component="div" variant="h5" color="secondary">
                    Nouns
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editMode}
                        onChange={() => setEditMode(!editMode)}
                        value="editMode"
                      />
                    }
                    label="Edit"
                    labelPlacement="start"
                  />
                </FlexTitle>

                {fields.length ? (
                  <List component="div">
                    {fields.map((name, index) => (
                      <Field
                        key={name}
                        name={name}
                        index={index}
                        component={ExpandableItem}
                        editMode={editMode}
                        onDelete={() => {
                          fields.remove(index)
                          checkClose("noun", name)
                        }}
                        onEdit={() => onEdit(name)}
                        onAddSection={() => {
                          const struct = `${name}.struct`
                          push(struct, emptySection())
                          const parentVal = getFieldState(name).value

                          const len = parentVal.struct.length
                          editSection(`${struct}[${len - 1}]`)
                        }}
                        onEditSection={editSection}
                        checkClose={checkClose}
                        form={form}
                      />
                    ))}
                    {provided.placeholder}
                  </List>
                ) : (
                  <Typography component="div">
                    There are no nouns on this planner!
                    <br />
                    Add a noun template or planner template to get some nouns
                    and start creating.
                  </Typography>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>
  )
}

export default NounList
