import React, { useState } from "react"
import { Button, Typography } from "@material-ui/core"

import Card from "components/shared/Card"
import { SelectList } from "components/shared/form"
const PlannerSelect = ({ items, loading, onConfirm }) => {
  const [selected, setSelected] = useState([])
  const onClick = x => {
    const ns = selected.slice(0)

    const index = ns.indexOf(x)
    if (index >= 0) {
      ns.splice(index)
    } else {
      ns.push(x)
    }
    setSelected(ns)
  }

  return (
    <Card
      title={
        <Typography variant="h5" color="inherit">
          Planner Template Select
        </Typography>
      }
    >
      <SelectList
        items={items}
        loading={loading}
        selectedList={selected}
        onClick={onClick}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => onConfirm(selected)}
      >
        Add Selected
      </Button>
    </Card>
  )
}
export default PlannerSelect
