import {
  API_BASE,
  EDITOR_CLOSETAB,
  EDITOR_SETTAB,
  EDITOR_UPDATETAB,
  NOUNITEM_ADD,
  NOUNITEM_DELETE,
  NOUNITEM_SAVE,
  NOUNITEM_LOAD,
  NOUNITEM_LIST
} from "lib/constants"

import { RSAA } from "redux-api-middleware"

const nounItemPath = "/items"

export const listNounItems = id => {
  const baseEnd = `${API_BASE}${nounItemPath}`
  let endpoint = baseEnd
  if (id) {
    endpoint = `${baseEnd}/planner/${id}`
  }
  return {
    [RSAA]: {
      endpoint,
      method: "GET",
      types: [
        NOUNITEM_LIST.REQUEST,
        NOUNITEM_LIST.SUCCESS,
        NOUNITEM_LIST.FAILURE
      ]
    }
  }
}

export const addNounItem = noun => async (dispatch, getState) => {
  let endpoint = `${API_BASE}${nounItemPath}`
  const result = await dispatch({
    [RSAA]: {
      endpoint,
      method: "POST",
      body: JSON.stringify({ noun }),
      headers: {
        "content-type": "application/json"
      },
      types: [NOUNITEM_ADD.REQUEST, NOUNITEM_ADD.SUCCESS, NOUNITEM_ADD.FAILURE]
    }
  })
  dispatch({
    type: EDITOR_SETTAB,
    payload: result.payload._id
  })
}

export const saveNounItem = item => ({
  [RSAA]: {
    endpoint: `${API_BASE}${nounItemPath}/${item._id}`,
    method: "PUT",
    body: JSON.stringify(item),
    headers: {
      "content-type": "application/json"
    },
    types: [NOUNITEM_SAVE.REQUEST, NOUNITEM_SAVE.SUCCESS, NOUNITEM_SAVE.FAILURE]
  }
})

export const loadNounItem = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${nounItemPath}/${id}`,
    method: "GET",
    types: [
      {
        type: NOUNITEM_LOAD.REQUEST,
        payload: (action, state) => id
      },
      NOUNITEM_LOAD.SUCCESS,
      {
        type: NOUNITEM_LOAD.FAILURE,
        meta: (action, state) => id
      }
    ]
  }
})

export const deleteNounItem = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${nounItemPath}/${id}`,
    method: "DELETE",
    types: [
      {
        type: NOUNITEM_DELETE.REQUEST,
        payload: (action, state) => id
      },
      {
        type: NOUNITEM_DELETE.SUCCESS,
        payload: (action, state) => id
      },
      {
        type: NOUNITEM_DELETE.FAILURE,
        meta: (action, state) => id
      }
    ]
  }
})

export const setTab = id => async (dispatch, getState) => {
  const {
    editor: { tabs }
  } = getState()
  const tab = tabs.find(x => x._id == id)
  if (!tab) await dispatch(loadNounItem(id))
  dispatch({
    type: EDITOR_SETTAB,
    payload: id
  })
}

export const updateTab = tab => ({
  type: EDITOR_UPDATETAB,
  payload: tab
})

export const closeTab = id => ({
  type: EDITOR_CLOSETAB,
  payload: id
})
