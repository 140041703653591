import React from "react"
import styled from "styled-components"
import { useTheme } from "@material-ui/styles"

const Container = styled.div`
  padding: 12px;
  min-width: 12vw;
`

const SearchDrawer = () => {
  const theme = useTheme()
  return <Container theme={theme}>SearchDrawer</Container>
}

export default SearchDrawer
