import {
  NEWS_LOAD,
  NEWS_LIST,
  NEWS_SAVE,
  NEWS_DELETE,
  NEWS_RECENT
} from "lib/constants"

const initialState = {
  current: null,
  list: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NEWS_LIST.REQUEST:
    case NEWS_SAVE.REQUEST:
    case NEWS_LOAD.REQUEST:
    case NEWS_DELETE.REQUEST:
    case NEWS_RECENT.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case NEWS_RECENT.SUCCESS:
    case NEWS_LIST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload
      }
    case NEWS_RECENT.FAILURE:
    case NEWS_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        apiError: action.payload
      }
    case NEWS_SAVE.SUCCESS: {
      const list = state.list.slice()
      const index = list.findIndex(x => x._id == action.payload._id)
      if (index >= 0) {
        list[index] = action.payload
      } else {
        list.push(action.payload)
      }

      return {
        ...state,
        isLoading: false,
        current: action.payload,
        list
      }
    }
    case NEWS_SAVE.FAILURE:
      return {
        ...state,
        isLoading: false,
        apiError: action.payload
      }
    case NEWS_LOAD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        current: action.payload
      }
    case NEWS_DELETE.SUCCESS: {
      const idx = state.list.findIndex(x => x._id == action.payload._id)
      const list = [...state.list.slice(0, idx), ...state.list.slice(idx + 1)]
      return {
        ...state,
        list,
        isLoading: false,
        current: null
      }
    }
    case NEWS_LOAD.FAILURE:
    case NEWS_DELETE.FAILURE:
      return {
        ...state,
        isLoading: false,
        current: null,
        apiError: action.payload
      }
    default:
      return state
  }
}
