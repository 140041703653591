import React from "react"
import styled from "styled-components"
import { useTheme } from "@material-ui/styles"
import OpenIcon from "@material-ui/icons/OpenInNew"
import { useHistory } from "react-router-dom"

const Mention = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
`

const OpenMention = styled(OpenIcon)`
  font-size: 1rem;
  margin-bottom: -2px;
  cursor: pointer;
`

const MentionNode = props => {
  const { id, attributes, children } = props
  const theme = useTheme()
  const history = useHistory()

  const onClick = () => {
    console.log("History push", `${id}`)
    history.push(`${id}`)
  }
  return (
    <Mention theme={theme} {...attributes}>
      {children}
      <OpenMention onClick={onClick} />
    </Mention>
  )
}

export default MentionNode
