import React, { useState } from "react"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"

const RTE = ({
  input: { name, value, ...restInput },
  meta,
  onChange,
  ...rest
}) => {
  const getContentState = value => {
    const contentBlock = htmlToDraft(value)
    return ContentState.createFromBlockArray(contentBlock.contentBlocks)
  }

  const initState = value => {
    const contentState = getContentState(value)
    return EditorState.moveFocusToEnd(
      EditorState.createWithContent(contentState)
    )
  }

  const theVal = initState(value)

  console.log(value, theVal)

  const [editorState, setEditorState] = useState(theVal)
  const [hideToolbar, setHideToolbar] = useState(true)
  const cV = draftToHtml(convertToRaw(editorState.getCurrentContent()))

  if (cV != value) {
    const contentState = getContentState(value)
    const wrapped = draftToHtml(convertToRaw(contentState))
    if (cV != wrapped) {
      setEditorState(
        EditorState.moveFocusToEnd(EditorState.createWithContent(contentState))
      )
    }
  }

  const onEditorStateChange = es => {
    setEditorState(es)
    const curValue = draftToHtml(convertToRaw(es.getCurrentContent()))
    if (value != curValue) {
      onChange(curValue)
    }
  }

  const handleClick = e => {
    console.log("handleClick", e)
  }

  return (
    <Editor
      toolbar={{
        options: ["inline", "blockType", "list", "colorPicker", "remove"],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true }
      }}
      toolbarHidden={hideToolbar}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName={rest.className}
      onFocus={() => setHideToolbar(false)}
      onBlur={() => setHideToolbar(true)}
      onClick={handleClick}
      spellCheck
      {...rest}
    />
  )
}
export default RTE
