import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { useTheme } from "@material-ui/styles"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard"
import {
  addNounItem,
  deleteNounItem,
  listNounItems,
  setTab
} from "../../actions/editorActions"
import {
  Collapse,
  List as MuiList,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import { Link } from "react-router-dom"

const Container = styled(Paper)`
  min-width: 12vw;
  padding-top: 0;
`
const StyledItem = styled(ListItem)`
  background-color: ${({ theme, depth }) =>
    depth ? "inherit" : theme.palette.primary.main};
  color: ${({ theme, depth }) =>
    depth ? "inherit" : theme.palette.primary.contrastText};
  &:hover {
    background-color: ${({ theme, depth }) =>
      depth ? "inherit" : theme.palette.primary.dark};
    color: ${({ theme, depth }) =>
      depth ? "inherit" : theme.palette.primary.contrastText};
  }
  padding-left: ${({ theme, depth }) => depth * theme.spacing(2)}px;
  padding-right: 0;
  a {
    text-decoration: none;
    color: inherit;
  }
`
const List = styled(MuiList)`
  padding-top: 0;
`
const ListItemIcon = styled.div`
  display: inline-flex;
  min-width: 28px;
  flex-shrink: 0;
  color: inherit;
`

const TreeNode = ({ name, depth, action, children = [], initOpen = true }) => {
  const [open, setOpen] = useState(initOpen)
  const theme = useTheme()
  return (
    <>
      <StyledItem
        dense
        button
        onClick={() => setOpen(!open)}
        depth={depth}
        theme={theme}
      >
        <ListItemIcon color="inherit">
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={depth ? "body1" : "button"}>{name}</Typography>
          }
        />
        {action && (
          <ListItemSecondaryAction>
            <IconButton onClick={action.onClick}>{action.icon}</IconButton>
          </ListItemSecondaryAction>
        )}
      </StyledItem>
      <Collapse in={open} unmountOnExit>
        {children.map(x =>
          x.children ? (
            <TreeNode depth={depth + 1} {...x} />
          ) : (
            <StyledItem
              dense
              button
              depth={depth + 1}
              theme={theme}
              key={x._id}
            >
              <ListItemIcon color="inherit">
                <Link to={x.to}>
                  <DeveloperBoardIcon />
                </Link>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant={depth ? "body1" : "button"}>
                    <Link to={x.to}>{x.name}</Link>
                  </Typography>
                }
              />
              {x.action && (
                <ListItemSecondaryAction>
                  <IconButton onClick={x.action.onClick}>
                    {x.action.icon}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </StyledItem>
          )
        )}
      </Collapse>
    </>
  )
}

export const FileDrawer = ({
  plannerName,
  plannerId,
  nouns = [],
  items = [],
  open = [],
  getUrl,
  addNounItem,
  deleteNounItem,
  listNounItems,
  setTab,
  itemsLoading
}) => {
  const children = nouns.map(n => ({
    name: n.name,
    children: items
      .filter(i => i.noun == n._id)
      .map(i => ({
        ...i,
        to: getUrl(i._id),
        action: {
          icon: <DeleteIcon />,
          onClick: () => {
            if (
              window.confirm(
                `Are you sure you want to delete the ${i.nounName} called ${
                  i.name
                }?`
              )
            ) {
              deleteNounItem(i._id)
            }
          }
        }
      })),
    key: n._id,
    action: {
      icon: <AddIcon />,
      onClick: () => addNounItem(n._id)
    }
  }))

  const openItems = open.map(n => ({
    _id: n._id,
    noun: n.noun._id,
    name: n.name,
    to: getUrl(n._id)
  }))

  useEffect(() => {
    if (itemsLoading || items.length >= 1) return //need a better way to terminate if planner has 0 items
    listNounItems(plannerId)
  }, [listNounItems, itemsLoading, items, plannerId])

  return (
    <Container>
      <List dense component="div">
        <TreeNode depth={0} name="Open" children={openItems} />
        <TreeNode depth={0} name={plannerName} children={children} />
      </List>
    </Container>
  )
}

const mapStateToProps = s => {
  let plannerName = ""

  let nouns = []

  if (s.planner.current) {
    plannerName = s.planner.current.name
    nouns = s.planner.current.nouns.map(({ name, _id }) => ({ name, _id }))
  }

  return {
    plannerName,
    nouns,
    items: s.editor.items,
    itemsLoading: s.editor.isLoading,
    open: s.editor.tabs
  }
}

export default connect(
  mapStateToProps,
  { addNounItem, deleteNounItem, listNounItems, setTab }
)(FileDrawer)
