import React, { useEffect } from "react"
import { List, ListItem, ListItemText, Typography } from "@material-ui/core"
import { listPlanners } from "../../actions/plannerActions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Fab } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

import Card from "components/shared/Card"

import styled from "styled-components"

const LinkList = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 0.2rem;
`
LinkList.displayName = "LinkList"

const FAB = styled(Fab)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const PlannerLink = styled.div`
  text-decoration: none;
`
PlannerLink.displayName = "PlannerLink"

export const PlannerList = ({ planners = [], loading, listPlanners }) => {
  useEffect(() => {
    listPlanners()
  }, [listPlanners])

  return (
    <Card
      title={
        <Typography variant="h5" color="inherit">
          My Planners
        </Typography>
      }
    >
      <List>
        {planners.map(p => {
          return (
            <ListItem
              button
              component={Link}
              key={`planner.${p._id}`}
              to={`/planner/${p._id}`}
            >
              <ListItemText>{p.name}</ListItemText>
            </ListItem>
          )
        })}
      </List>
      <FAB variant="round" color="secondary" component={Link} to="/new">
        <AddIcon />
      </FAB>
    </Card>
  )
}

const connected = connect(
  s => ({
    planners: s.planner.list,
    loading: s.planner.isLoading
  }),
  { listPlanners }
)(PlannerList)

connected.displayName = "Connect(PlannerList)"

export default connected
