import React, { useState } from "react"
import { Field } from "react-final-form"

import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core"
import DeveloperBoard from "@material-ui/icons/DeveloperBoard"
import List from "@material-ui/icons/List"

import CardTitle from "components/shared/CardTitle"
import { FormInput } from "components/shared/form"
import { FlexTitle } from "components/shared/styled"

import NounList from "../nouns/NounList"

const emptyNoun = () => ({
  name: "New Noun",
  desc: "New Noun description",
  struct: [],
  items: []
})

const PlannerConfigCard = ({
  pristine,
  submitting,
  form,
  templatePanel,
  editNoun,
  editSection,
  checkClose
}) => {
  const [tab, setTab] = useState(0)

  return (
    <Card
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 3fr 1fr 6fr 1fr",
        height: "calc(100vh - 64px - 2rem)"
      }}
    >
      <CardTitle>
        <FlexTitle>
          <Typography variant="h5" color="inherit">
            Planner Configuration
            {/* <AutoSave debounced={debouncedSave} />*/}
          </Typography>
          <Button
            type="submit"
            disabled={submitting}
            variant="text"
            color="inherit"
          >
            Save
          </Button>
        </FlexTitle>
      </CardTitle>
      <CardContent>
        <Field name="name" label="Name" component={FormInput} type="text" />

        <Field
          name="description"
          label="Description"
          component={FormInput}
          type="textarea"
        />
      </CardContent>
      <AppBar elevation={1} position="relative" color="default">
        <Tabs
          centered
          value={tab}
          onChange={(e, v) => setTab(v)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Nouns" icon={<DeveloperBoard />} />
          <Tab label="Lists" icon={<List />} />
        </Tabs>
      </AppBar>

      {tab == 0 && (
        <React.Fragment>
          <CardContent
            style={{
              overflow: "auto",
              maxHeight: "100%"
            }}
          >
            <NounList
              onEdit={editNoun}
              editSection={editSection}
              checkClose={checkClose}
              form={form}
            />
          </CardContent>
          <CardActions>
            <Button onClick={() => templatePanel("planner")} color="secondary">
              Add Planner Template
            </Button>
            <Button onClick={() => templatePanel("noun")} color="secondary">
              Add Noun Template
            </Button>
            <Button
              onClick={() => {
                const name = "nouns"

                form.mutators.push(name, emptyNoun())
                const len = form.getFieldState(name).length
                editNoun(`${name}[${len - 1}]`)
              }}
              color="secondary"
            >
              New Noun
            </Button>
          </CardActions>
        </React.Fragment>
      )}
      {tab == 1 && (
        <Typography component="div">Lists are coming soon.</Typography>
      )}
    </Card>
  )
}

export default PlannerConfigCard
