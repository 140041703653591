import React, { useEffect } from "react"
import { connect } from "react-redux"
import { AppBar, Tabs, Tab, IconButton, Typography } from "@material-ui/core"
import {
  saveNounItem,
  setTab,
  updateTab,
  closeTab
} from "../../actions/editorActions"
import ItemForm from "../items/ItemForm"
import { withStyles } from "@material-ui/styles"
import CloseIcon from "@material-ui/icons/Close"
import LensIcon from "@material-ui/icons/Lens"
import styled from "styled-components"
import { Link } from "react-router-dom"

const WSTabs = withStyles({
  indicator: {
    height: "5px"
  }
})(Tabs)

const WSTab = withStyles({
  selected: {
    backgroundColor: "rgba(0,0,0,.75)"
  }
})(Tab)

const TabGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
`

const TabLink = styled(Link)`
  color: white;
  text-decoration: none;
`
export const TabbedEditor = ({
  tabs,
  item,
  items,
  setTab,
  getUrl,
  saveNounItem,
  updateTab,
  closeTab,
  history
}) => {
  const value = tabs.findIndex(x => x._id == item)
  const tab = value > -1 ? tabs[value] : null

  useEffect(() => {
    const idx = tabs.findIndex(x => x._id == item)
    if (idx == -1) {
      setTab(item)
    }
  }, [tabs, item, setTab])

  const tabChange = (e, id) => {
    console.log("tab change")
    setTab(tabs[id]._id)
  }

  const tabClose = (id, isDirty) => {
    console.log("tab close")
    if (
      !isDirty ||
      window.confirm("This item has unsaved changes. Close it anyway?")
    ) {
      console.log(tabs)
      if (id == item) {
        //is the current tab
        if (tabs.length == 1) {
          history.push(getUrl())
        } else {
          let idx = tabs.findIndex(x => x._id == id)
          if (idx == tabs.length - 1) {
            idx--
          } else {
            idx++
          }
          history.push(getUrl(tabs[idx]._id))
        }
      }
      closeTab(id)
    }
  }

  return (
    <div>
      <AppBar position="static">
        <WSTabs
          value={value == -1 ? 0 : value}
          onChange={tabChange}
          indicatorColor="secondary"
        >
          {tabs.map(tab => (
            <WSTab
              component="div"
              label={
                <TabGrid>
                  <TabLink to={getUrl(tab._id)}>
                    <Typography variant="body1">{tab.name}</Typography>
                  </TabLink>

                  <IconButton
                    color="inherit"
                    onClick={e => {
                      e.stopPropagation()
                      tabClose(tab._id, tab.isDirty)
                    }}
                  >
                    {tab.isDirty ? <LensIcon /> : <CloseIcon />}
                  </IconButton>
                </TabGrid>
              }
              key={tab._id}
            />
          ))}
        </WSTabs>
      </AppBar>
      {tab && (
        <ItemForm
          key={tab._id}
          item={tab}
          items={items}
          handleSubmit={saveNounItem}
          updateTab={updateTab}
        />
      )}
    </div>
  )
}

export default connect(
  s => ({
    tabs: s.editor.tabs,
    items: s.editor.items
  }),
  { saveNounItem, setTab, updateTab, closeTab }
)(TabbedEditor)
