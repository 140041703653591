import React from "react"
import { Checkbox, List, ListItem, ListItemText } from "@material-ui/core"

const SelectList = ({ items = [], selectedList, onClick }) => (
  <List>
    {items.map(item => (
      <ListItem button onClick={() => onClick(item._id)} key={item._id}>
        <Checkbox
          checked={selectedList.includes(item._id)}
          tabIndex={-1}
          disableRipple
        />
        <ListItemText
          primary={item.name}
          secondary={<div dangerouslySetInnerHTML={{ __html: item.desc }} />}
          secondaryTypographyProps={{
            component: "div"
          }}
        />
      </ListItem>
    ))}
  </List>
)

export default SelectList
