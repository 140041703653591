import {
  NOUNITEM_ADD,
  NOUNITEM_LIST,
  NOUNITEM_LOAD,
  NOUNITEM_SAVE,
  EDITOR_SETTAB,
  EDITOR_UPDATETAB,
  EDITOR_CLOSETAB,
  AJAX_FAILED,
  AJAX_SUCCESS,
  AJAX_LOADING,
  PLANNER_LOAD,
  NOUNITEM_DELETE
} from "lib/constants"

const initialState = {
  items: [], //these are the items for this planner, just id, nounID, and name
  tabs: [], //these re the items we'e editing (might include more than that) -- it's their full data
  currentTab: null, //index of the current active tab.
  open: [], // a list of open items,
  loading: {} //hash of loading values
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NOUNITEM_ADD.REQUEST:
    case NOUNITEM_LIST.REQUEST:
    case NOUNITEM_SAVE.REQUEST:
    case NOUNITEM_DELETE.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case NOUNITEM_LOAD.REQUEST: {
      const loading = { ...state.loading }
      loading[action.payload] = AJAX_LOADING
      return {
        ...state,
        loading,
        isLoading: true
      }
    }
    case NOUNITEM_ADD.FAILURE:
    case NOUNITEM_LIST.FAILURE:
    case NOUNITEM_SAVE.FAILURE:
    case NOUNITEM_DELETE.FAILURE:
      return {
        ...state,
        isLoading: false,
        apiError: action.payload
      }
    case NOUNITEM_LOAD.FAILURE: {
      const loading = { ...state.loading }
      loading[action.meta] = AJAX_FAILED
      return {
        ...state,
        loading,
        isLoading: false,
        apiError: action.payload
      }
    }
    case NOUNITEM_LOAD.SUCCESS:
    case NOUNITEM_SAVE.SUCCESS:
    case NOUNITEM_ADD.SUCCESS: {
      const items = Array.from(state.items)
      const loading = { ...state.loading }
      const { payload } = action

      loading[payload._id] = AJAX_SUCCESS

      const item = {
        _id: payload._id,
        name: payload.name,
        noun: payload.noun._id
      }
      const itemIndex = items.findIndex(x => x._id == payload._id)
      if (itemIndex > -1) {
        items[itemIndex] = item
      } else {
        items.push(item)
      }
      const tabs = Array.from(state.tabs)

      const tabsIndex = tabs.findIndex(x => x._id == payload._id)
      if (tabsIndex > -1) {
        tabs[tabsIndex] = payload
      } else {
        tabs.push(payload)
      }

      return {
        ...state,
        items,
        tabs,
        loading,
        isLoading: false,
        currentTab: payload._id
      }
    }
    case NOUNITEM_LIST.SUCCESS: {
      const items = action.payload.length
        ? action.payload
        : [{ _id: -1, noun: -1, name: "No items" }]

      return {
        ...state,
        items,
        isLoading: false
      }
    }
    case NOUNITEM_DELETE.SUCCESS: {
      const currentIndex = state.tabs.findIndex(x => x._id == action.payload)
      let currentTab = state.currentTab
      if (currentIndex >= 0 && state.currentTab == action.payload) {
        if (currentIndex < state.tabs.length - 1) {
          currentTab = state.tabs[currentIndex + 1]._id
        } else if (state.tabs.length == 1) {
          //this is the only thing in the list
          currentTab = null
        } else {
          currentTab = state.tabs[currentIndex - 1]._id
        }
      }

      const tabs = state.tabs.reduce((acc, x) => {
        if (x._id != action.payload) acc.push(x)
        return acc
      }, [])
      const items = state.items.reduce((acc, x) => {
        if (x._id != action.payload) acc.push(x)
        return acc
      }, [])
      return {
        ...state,
        currentTab,
        items,
        tabs,
        isLoading: false
      }
    }
    case EDITOR_SETTAB: {
      return {
        ...state,
        currentTab: action.payload
      }
    }
    case EDITOR_UPDATETAB: {
      const tabs = Array.from(state.tabs)
      const tabsIndex = tabs.findIndex(x => x._id == action.payload._id)
      if (tabsIndex > -1) {
        tabs[tabsIndex] = action.payload
      } else {
        tabs.push(action.payload)
      }

      return {
        ...state,
        tabs
      }
    }
    case EDITOR_CLOSETAB: {
      const tabs = state.tabs.reduce((acc, tab) => {
        if (tab._id != action.payload) acc.push(tab)
        return acc
      }, [])
      return {
        ...state,
        tabs
      }
    }
    case PLANNER_LOAD.SUCCESS: {
      return { ...initialState }
    }
    default:
      return state
  }
}
