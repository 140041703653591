import styled from "styled-components"
import { palette } from "styled-theme"

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: ${palette("greyscale", 0, true)};
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
`

Label.displayName = "Label"

export default Label
