import React from "react"
import Lists from "@convertkit/slate-lists"
import { IconButton } from "@material-ui/core"

const list = () => {
  const plugin = Lists({
    blocks: {
      ordered_list: "ordered-list",
      unordered_list: "unordered-list",
      list_item: "list-item"
    },
    classNames: {
      ordered_list: "ordered-list",
      unordered_list: "unordered-list",
      list_item: "list-item"
    }
  })

  const single = (aria, icon, isActive, isDisabled, onClick) => {
    return (
      <IconButton
        className={`slate-button ${isActive && "active"}`}
        aria-label={aria}
        size="small"
        onClick={onClick}
      >
        {icon}
      </IconButton>
    )
  }

  const button = (editor, value, icons) => {
    const toggleUnorderedList = event => editor.toggleList().focus()
    const toggleOrderedList = event =>
      editor.toggleList({ type: "ordered-list" }).focus()
    const indent = event => editor.increaseListItemDepth().focus()
    const outdent = event => editor.decreaseListItemDepth().focus()

    const active = type => value.blocks.some(x => x.type == type)

    const isOrdered = active("ordered-list")
    const isUnordered = active("unordered-list")
    const isList = active("list-item")
    return (
      <>
        {single(
          "Unordered List",
          icons[0],
          isOrdered,
          false,
          toggleUnorderedList
        )}
        {single(
          "Ordered List",
          icons[1],
          isUnordered,
          false,
          toggleOrderedList
        )}
        {single("Indent", icons[2], isList, !isList, indent)}
        {single("Outdent", icons[3], isList, !isList, outdent)}
      </>
    )
  }
  return {
    plugin,
    key: "list",
    button
  }
}

export default list
