import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import editor from "./editorReducer"
import user from "./userReducer"
import planner from "./plannerReducer"
import templateSections from "./templateSectionReducer"
import templateNouns from "./templateNounReducer"
import templatePlanners from "./templatePlannerReducer"
import news from "./newsReducer"

export default history =>
  combineReducers({
    editor,
    user,
    planner,
    templateSections,
    templateNouns,
    templatePlanners,
    news,

    router: connectRouter(history)
  })
