import { API_BASE, ST_LOAD, ST_LIST, ST_SAVE } from "lib/constants"
import { RSAA } from "redux-api-middleware"
import { push } from "connected-react-router"

const path = "/admin/template/sections"
const nonadmin = "/templates/sections"

export const listSectionTemplates = () => ({
  [RSAA]: {
    endpoint: `${API_BASE}${nonadmin}`,
    method: "GET",
    types: [ST_LIST.REQUEST, ST_LIST.SUCCESS, ST_LIST.FAILURE]
  }
})

export const loadSectionTemplate = id => ({
  [RSAA]: {
    endpoint: `${API_BASE}${path}/${id}`,
    method: "GET",
    types: [ST_LOAD.REQUEST, ST_LOAD.SUCCESS, ST_LOAD.FAILURE]
  }
})

export const saveSectionTemplate = template => {
  return async (dispatch, getState) => {
    let endpoint = `${API_BASE}${path}`
    let method = "POST"
    if (template._id) {
      endpoint = `${endpoint}/${template._id}`
      method = "PUT"
    }

    if (typeof template.struct === "string") {
      try {
        template.struct = JSON.parse(template.struct)
      } catch (e) {
        debugger
        return {
          type: ST_SAVE.FAILURE,
          payload: {
            message: `Error parsing template struct: ${template.struct}`,
            error: {
              message: e.message,
              stack: e.stack
            }
          }
        }
      }
    }

    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method,
        body: JSON.stringify(template),
        headers: {
          "content-type": "application/json"
        },
        types: [ST_SAVE.REQUEST, ST_SAVE.SUCCESS, ST_SAVE.FAILURE]
      }
    })

    if (actionResponse.error) return actionResponse

    return dispatch(push(`${path}`))
  }
}
